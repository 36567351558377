import React from "react";

const ArrowRight = () => {
    return (
        <svg id="go_R" data-name="go R" xmlns="http://www.w3.org/2000/svg" width="110" height="67" viewBox="0 0 110 67">
            <path id="go_Right" data-name="go Right" d="M0,17.014,16.983,5.645,33.967,17.014V11.37L16.983,0,0,11.37Z" transform="translate(66 13.172) rotate(90)" fill="#fff" />
        </svg>
    );
};

export default ArrowRight;
