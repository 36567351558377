import React from "react";
import styles from './styles.js';

const SidePanel = ({ title, options, onSelectOption, selectedIndex }) => {
    return (
        <styles.ContainerMenu>
            {title && <styles.Title>{title}</styles.Title>}
            {options?.map((option, idx) => {
                return <styles.MenuOption selected={selectedIndex === idx} onClick={() => onSelectOption(idx)}>{(idx+1).toString().padStart(3, "0")}&nbsp;{option.name}</styles.MenuOption>
            })}
        </styles.ContainerMenu>
    );
};

export default SidePanel;
