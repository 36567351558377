import styled from "styled-components";

const Container = styled.div`
  width: 1200px;
`;

const ContainerImages = styled.div`
  position: relative;
  overflow: hidden;
  height: 251px;
  width: 505px;

  @media only screen and (min-width: 1200px) {
    height: 342px;
    width: 686px;
  }
`;

export default {
  Container,
  ContainerImages,
}