import React from "react";
import styles from './styles.js';
import Chevron from "../Chevron";

const List = ({ hiddenDesktop, title, elements, handleElementClick }) => {
    return (
        <styles.ListContainer hiddenDesktop={hiddenDesktop} title={title}>
            <styles.ListTitle>{title}</styles.ListTitle>
            {elements?.map((element, idx) => {
                return <styles.ListElement key={idx} onClick={()=>handleElementClick(idx)}>
                        {element.name}
                    <styles.ArrowContainer><Chevron /></styles.ArrowContainer>
                </styles.ListElement>
            })}
        </styles.ListContainer>
    );
};

export default List;
