import React, { useEffect, useState } from "react";
import styles from './styles.js';
import Grid from 'components/Grid';
import List from 'components/List';
import getGallery from 'helpers/getGallery';
import VisibilitySensor from 'react-visibility-sensor';
import { useHistory } from "react-router";

const Interiors = React.forwardRef((props, ref) => {
    const [gallery, setGallery] = useState([]);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const loadedGallery = await getGallery();
            setGallery(loadedGallery);
        })();
    }, []);

    const showGallery = (idx)=>{
        history.push(`/interiors/${gallery[idx].galleryurl}`)
    }

    const changeVisibility = (isVisible)=>{
        if(isVisible){
          history.push('landing?section=interiors&type=scrolled')
        }
      }

    return (
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
            <styles.PortfolioContainer ref={ref}>
                <styles.Title>
                    Szklane drzwi do każdego wnętrza
                </styles.Title>
                <styles.Subtitle>
                    Prostota, elegancja i doskonała harmonia z otoczeniem to podstawowe zalety aranżacji stworzonych
                    przy użyciu drzwi całoszklanych.
                </styles.Subtitle>
                <styles.ContainerGrid><Grid elements={gallery} handleElementClick={showGallery}/> </styles.ContainerGrid>
                <styles.ContainerList><List title={"WNĘTRZA"} elements={gallery} handleElementClick={showGallery}/></styles.ContainerList>
            </styles.PortfolioContainer>
        </VisibilitySensor>
    );
});

export default Interiors;
