import React from "react";

const ArrowLeft = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.014" height="33.967" viewBox="0 0 17.014 33.967">
            <path id="go_Right" data-name="go Right" d="M0,17.014,16.983,5.645,33.967,17.014V11.37L16.983,0,0,11.37Z" transform="translate(17.014) rotate(90)" fill="#fff" />
        </svg>
    );
};

export default ArrowLeft;
