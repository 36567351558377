import styled, { keyframes } from "styled-components";

const containerHeight = 600;

const HomeContainer = styled.div`
  width: 100%;
  height: ${containerHeight}px;
  max-height: 100vh;
  box-sizing: border-box;
  margin:0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const SingleLeafAnimation = keyframes`
  from {
    left: -207px;
    top: 30px;
  }
  to {
    left: -185px;
    top: -38px;
  }
`;

const SingleLeaf = styled.img`
  position: absolute;
  left: -185px;
  top: -38px;
  animation: ${SingleLeafAnimation} 2s ease-in-out;
  animation-iteration-count: 1;
`;

const MultiLeafAnimation = keyframes`
  from {
    top: 23px;
    left: -207px;
  }
  to {
    top: 209px;
    left: -117px;
  }
`;

const MultiLeaf = styled.img`
  position: absolute;
  top: 209px;
  left: -117px;
  animation: ${MultiLeafAnimation} 2s ease-in-out;
  animation-iteration-count: 1;
`;


const BackgroundAnimation = keyframes`
  from {
    left: -357px;
    top: -57px;
  }
  to {
    left: -157px;
    top: -207px;
  }
`;

const Background = styled.img`
  position: absolute;
  left: -157px;
  top: -207px;
  min-width: 120vw;
  width: auto;
  min-height: 900px;
  height: auto;
  animation: ${BackgroundAnimation} 2s ease-in-out;
  animation-iteration-count: 1;
`;

const ContainerHeroLogo = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    padding-top: 64.5px
    width: 100%;
  }
  @media only screen and (min-width: 768px){
    padding-top: 64.5px
    width: 100%;
  }
  @media only screen and (min-width: 1024px){
    width: 50%;
  }
`;

const ContainerGlass = styled.div`
  z-index: 2;
  width: 50%;
  height: 600px;
  border-left: 2px solid #707070;
  border-rigth: 2px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1024px){
    display: none;
  }
`;

const Glass = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 64.5px
`;

const MenuOption = styled.div`
  z-index: 3;
  color: #1F1F1F;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 500;
  height: 60px;
  &:hover{
    color: #C6C5C4;
  }
`;


const HeroLogoAnimationDesktop = keyframes`
  from {
    width: 300px;
    opacity: 0.2;
  }
  to {
    width: 478px;
    opacity: 1;
  }
`;

const HeroLogoAnimationTablet = keyframes`
  from {
    width: 250px;
    opacity: 0.2;
  }
  to {
    width: 395px;
    opacity: 1;
  }
`;

const HeroLogoAnimationPhone = keyframes`
  from {
    width: 250px;
    opacity: 0.2;
  }
  to {
    width: 300px;
    opacity: 1;
  }
`;

const ImageLogo = styled.img`
  width: 300px;
  animation: ${HeroLogoAnimationPhone} 2s ease-in-out;
  heigth: auto;
  
  @media only screen and (min-device-width: 600px) {
    width: 395px;
    animation: ${HeroLogoAnimationTablet} 2s ease-in-out;
  }
  @media only screen and (min-device-width: 768px) {
    width: 478px;
    animation: ${HeroLogoAnimationDesktop} 2s ease-in-out;
  }
`;

const FooterUp = styled.div`
  width: 100vw;
  heigth: 0.1px;
`;


export default {
  HomeContainer,
  SingleLeaf,
  MultiLeaf,
  Background,
  ImageLogo,
  ContainerHeroLogo,
  ContainerGlass,
  Glass,
  ContainerMenu,
  MenuOption,
  FooterUp,
}