import React from "react";

const ArrowLeft = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="67" viewBox="0 0 103 67">
            <path id="go_Right" data-name="go Right" d="M0,0,16.983,11.369,33.967,0V5.644L16.983,17.014,0,5.644Z" transform="translate(73.014 13.172) rotate(90)" fill="#aaa" />
        </svg>
    );
};

export default ArrowLeft;
