import React from "react";

const RightImg = () => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" width="75.2" height="93.984" viewBox="0 0 75.2 93.984">
                <defs>
                    <filter id="go_right" x="0" y="0" width="75.2" height="93.984" filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="7.5" result="blur"/>
                    <feFlood flood-opacity="0.302"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#go_right)">
                    <path id="go_right-2" data-name="go right" d="M1391.465,410.993,1410.526,387l-19.061-23.993h9.463L1419.99,387l-19.062,23.992Z" transform="translate(-1367.93 -340.01)" fill="#fff" stroke="rgba(112,112,112,0)" stroke-width="1"/>
                </g>
            </svg>
    );
};

export default RightImg;



