import CMSAPI from "cmsapi";

const getGallery = async () => {
    const galleryEndpoint = "gallery-homes";
    const endpoint = `${CMSAPI}/${galleryEndpoint}`;
    const data = await fetch(endpoint)
        .then((response) => response.json())
        .then((data) => data)
        .catch(function (err) {
            console.log('Fetch Error: ', err);
        });
    return data;
};

export const getData = async (urlendpoint) => {
    const endpoint = `${CMSAPI}/${urlendpoint}`;
    const data = await fetch(endpoint)
        .then((response) => response.json())
        .then((data) => data)
        .catch(function (err) {
            console.log('Fetch Error: ', err);
        });
    return data;
};

export default getGallery;