import React, { useEffect, useState } from "react";
import styles from './styles.js';
import Grid from 'components/Grid';
import { getData } from 'helpers/getGallery';
import { useHistory } from "react-router";
import VisibilitySensor from 'react-visibility-sensor';

const Patterns = React.forwardRef(({ }, ref) => {
    const history = useHistory();
    const [types, setTypes] = useState([]);

    useEffect(() => {
        (async () => {
            const loadedTypes = await getData('pattern-types');
            setTypes(loadedTypes);
        })();
    }, []);

    const showGallery = (idx) => {
        history.push(`/patterns/${types[idx].galleryurl}`)
    }

    const changeVisibility = (isVisible) => {
        if (isVisible) {
            history.push('landing?section=patterns&type=scrolled')
        }
    }

    return (
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
            <styles.PortfolioContainer ref={ref}>
                <styles.Line />
                <styles.TextContainer>
                    <styles.Title>
                        WZORY
                    </styles.Title>
                    <styles.Subtitle>
                        Prezentujemy teczniczne i plastyczne rozwiązania oraz zastosowanie w pomieszczeniach prywatnych i komercyjnych. Oferujemy wzory piaskowane, frezowane, wykonane techniką witrażową lub barwione folią kolorową.
                    </styles.Subtitle>
                </styles.TextContainer>
                <Grid elements={types} handleElementClick={showGallery} />
            </styles.PortfolioContainer>
        </VisibilitySensor>
    );
});

export default Patterns;
