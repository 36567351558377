import React, { useState, useEffect } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import SidePanel from "components/SidePanel/index.js";
import GalleryCompressedLong from "components/GalleryCompressedLong";

const Handlers = () => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [types, setTypes] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        (async () => {
            const loadedTypes = await getData('typy-oscieznices');
            setTypes(loadedTypes);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (types?.length) {
                const loadedGallery = await getData(`${types[selectedIndex].galleryurl}`);
                setGallery(loadedGallery);
            }
        })();
    }, [types, selectedIndex]);
    return (
        <>
            <styles.ContentContainer>
                <styles.ContainerMenu>
                    <SidePanel title={"OŚCIEŻNICE"} options={types} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
                </styles.ContainerMenu>
                <styles.ContainerGallery>
                    <GalleryCompressedLong gallery={gallery} onlyDots />
                    <styles.TextTitle>
                        {types?.length && types[selectedIndex].title}
                    </styles.TextTitle>
                    <styles.Text>
                        {types?.length && types[selectedIndex].description}
                    </styles.Text>
                </styles.ContainerGallery>
            </styles.ContentContainer>
        </>
    );
};

export default Handlers;
