import styled from "styled-components";

const ModalContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px
  background-color:  white;
  z-index: 50;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 90px;
  text-align: right;
  @media only screen and (max-width: 992px) {
    background-color:  ${({fullModal})=> fullModal ? 'black':'#3F3F3F'};
    align-items: center;
    padding-top: 0px;
    justify-content: center;
    flex-direction: column;
  }
`;

const NavbarModal = styled.div`
  width: 100vw;
  height: 81px;
  position: fixed;
  top: 0px;
  left: 0px
  z-index: 200;
  background-color: white
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerDoor = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  heigth: 100%;
`;

const TextOverDoor = styled.div`
  color: #3F3E3E
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  padding-top: 11px;
  border-top: 1px solid #000000;
  width: 100%;
  margin-bottom: 30px;
`;

const ShowCaseContainer = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerListButton = styled.div`
  position: absolute;
  left: 35px;
  cursor: pointer;
  z-index: 200;
  &:hover {
    & >svg>g>line{
      stroke: #2f2f2f
    }
  }
`;

const ContainerMenu = styled.div`
  margin-top: 10px;
  width: 388px;
  margin-right: 60px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const Door = styled.img`
  margin-top: auto;
  width: 196px;
  height: 402px;
  flex-shrink: 0;
`;

const ContainerGallery = styled.div`
  width: 825px;
  min-width: 60%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;


const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const TitlePhone = styled.div`
  margin-top: 130px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 36px;
  color: #C6C5C4;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const LabelContainer = styled.div`
  position: absolute;
  left: 0px;
  width: 230px;
  background-color: rgba(198, 197, 196, 0.41);
  height: 50px;
  top: 645px;
  display: flex;
  align-items: center;
  padding-right: 7px;
  justify-content: flex-end;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export default {
  ModalContainer,
  ContainerMenu,
  ContainerGallery,
  GridContainer,
  TitlePhone,
  NavbarModal,
  ContainerListButton,
  ShowCaseContainer,
  Door,
  ContainerDoor,
  TextOverDoor,
  LabelContainer,
}