import React from "react";
import styles from './styles.js';
import CatalogImage from 'media/images/mokup@2x.png';

const Catalog = () => {
    return (
        <styles.CatalogContainer >
            <styles.CatalogImage src={CatalogImage} />
            <styles.ContainerButtons>
                <styles.Button>Zobacz katalog</styles.Button>
                <styles.Button href="/pdf/katalog.pdf" download="katalog.pdf">Pobierz katalog</styles.Button>
            </styles.ContainerButtons>
        </styles.CatalogContainer>
    );
};

export default Catalog;
