import styled from "styled-components";

const ContainerPanel = styled.div`
  position: fixed;
  top: 0px;
  right: ${({open}) => open ? '0px': '-240px'};
  width: 240px;
  height: 100vh;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: all 0.7s;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 100px;
`;

const MenuOption = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  height: 40px;
  &:hover{
    color: #C6C5C4;
  }
`;

const MenuOptionLink = styled.a`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  height: 40px;
  &:hover{
    color: #C6C5C4;
  }
`;


const BackPanel = styled.div`
  position: fixed;
  top: 0px;
  right: ${({open}) => open ? '0px': '-100vw'};
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

export default {
  ContainerPanel,
  ContainerMenu,
  MenuOption,
  BackPanel,
  MenuOptionLink,
}
