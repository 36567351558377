import React, { useState, useEffect } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import GalleryCompressed from "components/GalleryCompressed";
import SidePanel from "components/SidePanel/index.js";
import ArrowDownSingle from 'components/ArrowDownSingle';

const Handlers = ({toggleFrames, framesOpen}) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [okuciaTypow, setOkuciaTypow] = useState([]);
    const [elements, setElements] = useState([]);
    const [gallery, setGallery]= useState([]);
    const [typIdxSelected, setTypIdxSelected] = useState(0);
    const [galleryIndex, setGalleryIndex] = useState(0);
    useEffect(() => {
        setSelectedIndex(0);
        (async () => {
            if(okuciaTypow?.length){
                const loadedItems = await getData(`${okuciaTypow[typIdxSelected].elementsurl}`);
                setElements(loadedItems);
            }
        })();
    }, [okuciaTypow, typIdxSelected]);


    useEffect(() => {
        (async () => {
            if(elements?.length){
                setGallery([]);
                const loadedItems = await getData(`${elements[selectedIndex].galleryurl}`);
                if(loadedItems){
                    setGallery(loadedItems);
                }
            }
        })();
    }, [elements, selectedIndex]);

    useEffect(() => {
        (async () => {
            const loadedItems = await getData('okucia-typows');
            setOkuciaTypow(loadedItems);
        })();
    }, [])

    const selectTyp = (idx) => {
        setTypIdxSelected(idx)
    }


    const updateGalleryIndex = (i)=>{
        setGalleryIndex(i);
    }

    return (
        <>
            <styles.ContentContainer>
                <styles.ContainerMenu>
                    <SidePanel title={"OKUCIA"} options={elements} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
                    <styles.ContainerArrowDown>
                        <ArrowDownSingle open={framesOpen} toggle={toggleFrames} messageOpen={framesOpen ? 'Zwiń ościeżnice' : 'Rozwiń ościeżnice dla drzwi szklanych'} />
                        <styles.NextSectionDivider open={framesOpen}/>
                    </styles.ContainerArrowDown>
                </styles.ContainerMenu>
                <styles.ContainerGallery>
                    <styles.ContainerTypow>
                        {okuciaTypow?.map((okucia, idx) => <>{idx !== 0 && <styles.MiddleLine>|</styles.MiddleLine>}<styles.Typ selected={idx === typIdxSelected} onClick={() => selectTyp(idx)}>{okucia.name}</styles.Typ></>)}
                    </styles.ContainerTypow>
                    {gallery?.length && <GalleryCompressed gallery={gallery} onlyDots updateGalleryIndex={updateGalleryIndex}/>}
                    <styles.TextTitle>
                        {gallery?.length && gallery[galleryIndex].name}
                    </styles.TextTitle>
                    <styles.Text>
                        {elements?.length && elements[selectedIndex].description}
                    </styles.Text>
                </styles.ContainerGallery>
            </styles.ContentContainer>
        </>
    );
};

export default Handlers;
