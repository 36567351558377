import React, { useState, useEffect } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import SidePanel from "components/SidePanel";
import SidePanelDoors from "components/SidePanelDoors";
import SliderDoors from "components/SliderDoors";
import BgGlassTypes from 'media/images/BgGlassTypes@2x.png';
import VisibilitySensor from 'react-visibility-sensor';
import { useHistory } from "react-router";

const GlassesTypes = React.forwardRef(({ toggleHandlers, handlersOpen, updateDoorTypeParent }, ref) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [doorTypes, setDoorTypes] = useState([]);
    const [gallery, setGallery] = useState([]);
    const history = useHistory();
    useEffect(() => {
        setGallery([]);
        (async () => {
            if (doorTypes?.length) {
                const loadedGallery = await getData(`${doorTypes[selectedIndex].galleryurl}`);
                if (loadedGallery) {
                    setGallery(loadedGallery);
                }
            }
        })();
    }, [doorTypes, selectedIndex]);

    useEffect(() => {
        (async () => {
            const loadedDoorTypes = await getData('rodzaje-szklas');
            setDoorTypes(loadedDoorTypes);
        })();
    }, [])

    const changeVisibility = (isVisible) => {
        if (isVisible) {
            history.push('landing?section=glasses&type=scrolled')
        }
    }

    const updateDoorType = (idx) => {
        setSelectedIndex(idx);
        updateDoorTypeParent(idx);
    }

    return (
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
            <styles.GlasssTypesContainer ref={ref} open={handlersOpen} image={BgGlassTypes}>
                <styles.ContentContainer>
                    <styles.ContainerMenuMobile>
                        <SidePanelDoors title={"RODZAJE SZKŁA"} options={doorTypes} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
                    </styles.ContainerMenuMobile>
                    <styles.SliderMobileContainer>
                        <SliderDoors gallery={gallery} doorType={doorTypes[selectedIndex]}/>
                    </styles.SliderMobileContainer>
                    <styles.ContainerMenu>
                        <SidePanel title={"RODZAJE SZKŁA"} options={doorTypes} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
                    </styles.ContainerMenu>
                    <styles.ContainerGallery>
                        <SliderDoors gallery={gallery} doorType={doorTypes[selectedIndex]}/>
                    </styles.ContainerGallery>
                </styles.ContentContainer>
            </styles.GlasssTypesContainer>
        </VisibilitySensor>
    );
});

export default GlassesTypes;
