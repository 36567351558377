import React from "react";
import styles from './styles.js';

const SidePanel = ({ title, options, onSelectOption, selectedIndex }) => {
    return (
        <styles.ContainerMenu>
            <styles.Title>{title}</styles.Title>
            {options?.map((option, idx) => {
                return <styles.MenuOption selected={selectedIndex === idx} onClick={() => onSelectOption(idx)}>{option.name}</styles.MenuOption>
            })}
        </styles.ContainerMenu>
    );
};

export default SidePanel;
