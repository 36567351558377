import styled from "styled-components";

const imageMargin = 20;

const Image = styled.div`
  position: absolute;
  top: 0px;
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.7s;
  margin-right: ${imageMargin}px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ idx, selectedIdx }) => ((idx - selectedIdx) * (505 + imageMargin))}px;
  height: 416px;
  width: 505px;

  @media only screen and (min-width: 1200px) {
    left: ${({ idx, selectedIdx }) => ((idx - selectedIdx) * (686 + imageMargin))}px;
    height: 566px;
    width: 686px;
  }

`;

export default {
  Image,
}