import styled from "styled-components";
import { NavLink } from 'react-router-dom';

const Link = styled(NavLink)`
  color: #3F3F3F;
  width: 137px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 19px;
  font-stretch: condensed;
  &:hover{
    color: #C6C5C4;
  }
`;

const ALink = styled.a`
  color: #3F3F3F;
  width: 137px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 19px;
  font-stretch: condensed;
  &:hover{
    color: #C6C5C4;
  }
`;

const LogoLink = styled(NavLink)`

`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 101;
  height: 100%;
`;

const Menu = styled.ul`
  width: 100%;
  display: flex;
  flex-directio: row;
  list-style-type: none;
  justify-content: flex-end;
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 160px;
  margin-top: 8px;

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export default {
  Link,
  ALink,
  LogoLink,
  Logo,
  Nav,
  Menu,
}
