import styled from "styled-components";

const ContainerArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  ${({ open }) => open && 'transform: rotate(180deg);'}
  transition: all 0.6s;
`;

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #C6C5C4;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  &:hover {
    color: #2e2e2e;
    & > div > svg > path{
      fill: #2e2e2e;
    }
  }
  & > div > svg > path{
    transition: all 0.3;
  }
`;

export default {
  ContainerArrow,
  ContainerButton,
}
