import React, { useEffect } from "react";
import styles from './styles.js';
import Image from './Image'
import ArrowsGallery from 'components/ArrowsGallery';
import Sequencer from 'components/Sequencer';
import createHooks from 'components/hooks/galleryHooks';

const Gallery = ({ gallery, height, onlyDots, updateGalleryIndex }) => {

    const { selectedIndex, setSelectedIndex, leftUnavailable, rightUnavailable, goLeft, goRight } = createHooks(gallery);
    useEffect(()=>{
        updateGalleryIndex(selectedIndex);
    }, [selectedIndex])
    return (
        <styles.Container>
            <Sequencer onlyDots={onlyDots} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} leftUnavailable={leftUnavailable} rightUnavailable= {rightUnavailable} goLeft={goLeft} goRight={goRight} gallery={gallery}/>
            <styles.ContainerImages height={height}>
                <ArrowsGallery leftUnavailable={leftUnavailable} rightUnavailable= {rightUnavailable} goLeft={goLeft} goRight={goRight}/>
                {gallery?.map((element, idx) => {
                    return (
                        <Image element={element} idx={idx} selectedIndex={selectedIndex} />
                    )
                })}
            </styles.ContainerImages>
        </styles.Container>
    );
};

export default Gallery;
