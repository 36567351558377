import styled from "styled-components";

const TopHeader = styled.header`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 64.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  padding: 0 0;
  background-color: white;
  @media screen and (min-width: 992px) {
    border-bottom: 1px solid #bcbcbc;
  }
  @media screen and (max-width: 992px) {
    background-color: transparent;
    ${({modalShown})=> modalShown && 'display: none;'};
  }
`;

const HeaderElementsContainer = styled.div`
  width: 100%;
  max-width: 1084px;
  height: 64.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 992px) {
    width: 804px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1084px;
  }
  
`;

export default {
  TopHeader,
  HeaderElementsContainer,
}
