import styled from "styled-components";

const TitleContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #2E2E2E;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  color: #C6C5C4;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const LeftBar = styled.div`
  position: absolute;
  width: 37px;
  left: 0px;
  top: 0px
  height: ${({open})=> open ? '464px;' : '0%'};
  max-height: 90%;
  margin-top: 35px;
  background-color: rgba(46, 46, 46, 0.35);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  &>svg{
    ${({expanded})=>expanded && 'transform: rotate(180deg);'}
    transition: all 0.3s;
  }
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const DetailText = styled.div`
  position: absolute;
  width: 100vw;
  z-index: 10;
  left: ${({expanded})=> expanded ? '0' : '-100vw'};
  top: 0px
  height: 464px;
  max-height: 90%;
  margin-top: 35px;
  background-color: white;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const Line = styled.div`
  position: absolute;
  width: ${({open})=> open ? '100%' : '50%'};
  bottom: 0px;
  left: 0px;
  height: 2px;
  background-color: #2E2E2E;
  transition: all 0.3s;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: ${({open})=> open ? '200hw': '0vh'};
  overflow: hidden;
  transition: all 1s;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }

`;

const ContentDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 204px;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const DetailTitle = styled.div`
  color: #2E2E2E;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  text-align: right;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 500;
  height: 60px;
  margin-bottom: 20px;
  z-index: 12;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const DetailDescription = styled.div`
  margin-top: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #3E3E3E;
  text-align: left;
  @media only screen and (min-width: 992px) {
    display: none;
    visibility: none;
  }
`;



export default {
  TitleContainer,
  Title,
  Line,
  ContentContainer,
  ContentDetailContainer,
  SliderContainer,
  LeftBar,
  DetailText,
  DetailTitle,
  DetailDescription,
}