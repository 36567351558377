import styled, { keyframes, css } from "styled-components";

const containerHeight = 600;

const HomeContainer = styled.div`
  width: 100%;
  height: ${containerHeight}px;
  max-height: 100vh;
  box-sizing: border-box;
  margin:0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const SingleLeaf = styled.img`
  position: absolute;
  left: ${({loaded})=>loaded ? '-185px' : '-207px'};
  top: ${({loaded})=>loaded ? '-38px' : '30px'};
  z-index: 1;
  transition: all 1.5s ease-in-out ;
`;

const MultiLeaf = styled.img`
  position: absolute;
  left: ${({loaded})=>loaded ? '-117px' : '-207px'};
  top: ${({loaded})=>loaded ? '209px' : '23px'};
  z-index: 3;
  transition: all 1.5s ease-in-out ;
`;

const Background = styled.img`
  position: absolute;
  left: ${({loaded})=>loaded ? '-157px' : '-357px'};
  top: ${({loaded})=>loaded ? '-207px' : '-57px'};
  min-width: 120vw;
  width: auto;
  min-height: 900px;
  height: auto;
  animation-iteration-count: 1;
  transition: all 1.5s ease-in-out ;
`;

const ContainerHeroLogo = styled.div`
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    padding-top: 64.5px
    width: 100%;
  }
  @media only screen and (min-width: 768px){
    padding-top: 64.5px
    width: 100%;
  }
  @media only screen and (min-width: 992px) {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const ContainerImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 992px) {
    width: 402px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media only screen and (min-width: 1200px) {
    width: 542px;
  }
`;


const ContainerGlass = styled.div`
  z-index: 4;
  width: 50%;
  height: 600px;
  border-left: 2px solid #707070;
  border-right: 2px solid #707070;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media only screen and (max-width: 1024px){
    display: none;
  }
`;

const Glass = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 64.5px;
  @media only screen and (min-width: 992px) {
    width: 402px;
  }
  @media only screen and (min-width: 1200px) {
    width: 542px;
  }
`;

const MenuOption = styled.div`
  z-index: 3;
  color: #1F1F1F;
  font-size: 48px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: bold;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  &:hover{
    opacity: 0.4;
  }
`;

const ImageLogo = styled.img`
  width: ${({loaded})=>loaded ? '300px' : '250px'};
  opacity: ${({loaded})=>loaded ? '1' : '0.01'};
  transition: all 1.5s ease-in-out ;
  height: auto;
  max-width: 80%;
  @media only screen and (min-device-width: 600px) {
    width: ${({loaded})=>loaded ? '395px' : '250px'};
    opacity: ${({loaded})=>loaded ? '1' : '0.01'};
  }
  @media only screen and (min-device-width: 768px) {
    width: ${({loaded})=>loaded ? '478px' : '250px'};
    opacity: ${({loaded})=>loaded ? '1' : '0.01'};
  }
`;

export default {
  HomeContainer,
  SingleLeaf,
  MultiLeaf,
  Background,
  ImageLogo,
  ContainerHeroLogo,
  ContainerGlass,
  Glass,
  ContainerMenu,
  MenuOption,
  ContainerImage,
}