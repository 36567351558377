import styled from "styled-components";

const CloseModalButton = styled.div`
  color: black;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
`;

const CloseModalSvg = styled.svg`
  margin-left: 20px;
`;

export default {
  CloseModalButton,
  CloseModalSvg,
}
