import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import styles from './styles';
import { ModalContext } from 'ModalContext';
import CloseModalButton from './CloseModalButton/index';
import NavElements from './NavElements/index';
import SideMenu from './SideMenu/index';

const Header = () => {
  const [open, setOpen] = useState(false);
  const { modalShown, toggleModal, closeMessage } = useContext(ModalContext);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      {!modalShown &&
        <styles.TopHeader modalShown={modalShown}>
          <styles.HeaderElementsContainer>
            {!modalShown ? <NavElements open={open} toggle={toggle} /> : <CloseModalButton toggleModal={toggleModal} closeMessage={closeMessage} />}
          </styles.HeaderElementsContainer>
        </styles.TopHeader>
      }
      <SideMenu open={open} toggle={toggle} />
    </>

  );
};

export default withRouter(Header);
