import styled from "styled-components";

const CatalogContainer = styled.div`
  width: 100%;
  min-height: 720px;
  background-color: #DCDBD9;
  box-sizing: border-box;
  margin:0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  background-image: ${({image})=> `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  

  @media only screen and (max-width: 600px) {
    width: 400px;
    max-width: 95%;
  }

  @media only screen and (min-width: 600px) {
    width: 550px;
  }

  @media only screen and (min-width: 768px) {
    width: 550px;
  }

  @media only screen and (min-width: 992px) {
    align-items: flex-end;
    justify-content: flex-end;
    width: 825px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1100px;
  }
`;

const Button = styled.a`
  width: 350px;
  height: 60px;
  border: 1px solid #3F3F3F;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  color: #2E2E2E;
  cursor: pointer;
  margin: 10px;
  z-index: 10;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
`;

const CatalogImage = styled.img`
  margin-top: 20px;
  max-height: 80%;
  -webkit-filter: drop-shadow(20px 60px 99px rgba(0,0,0,0.4));

  @media only screen and (max-width: 600px) {
    min-width: 400px;
    max-width: 95%;
  }

  @media only screen and (min-width: 600px) {
    min-width: 550px;
  }

  @media only screen and (min-width: 768px) {
    min-width: 550px;
  }

  @media only screen and (min-width: 992px) {
    position: absolute;
    top: 100px;
    align-items: flex-end;
    justify-content: flex-end;
    min-width: 825px;
  }

`;


export default {
  ContainerButtons,
  CatalogContainer,
  Button,
  CatalogImage,
}