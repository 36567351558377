import styled from "styled-components";

const imageMargin = 20;

const Container = styled.div`
  width: 1200px;
`;

const ContainerImages = styled.div`
  width: 1200px;
  position: relative;
  overflow: hidden;
  height: 416px;
  @media only screen and (min-width: 1200px) {
    height: 566px;
  }
`;

export default {
  Container,
  ContainerImages,
}