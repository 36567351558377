import styled from "styled-components";

const ContainerSequencerElement = styled.div`
width: 100px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
& > svg > path {
  ${({disabled})=> disabled ? 'fill: #707070;' : 'fill: #eeeeee;'}
}
  &: hover{
    & > svg > path {
      fill: #707070;
      transition: all 0.2s;
    }
  }
`;

const TextContainer = styled.div`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
`;

const MiddleDot = styled.div`
  width:5px;
  height:5px;
  border-radius:5px;
  border: 1px solid #707070;
  ${({selected})=>selected && 'background-color: #707070;'}
  transition: all 0.7s;
`;

export default {
  MiddleDot,
  ContainerSequencerElement,
  TextContainer,
}