import React from 'react';
import styles from './styles';

const CloseModalButton = ({toggleModal, closeMessage}) => {
    return (
        <styles.CloseModalButton onClick={toggleModal}>{closeMessage}
            <styles.CloseModalSvg xmlns="http://www.w3.org/2000/svg" width="31.114" height="28.991" viewBox="0 0 31.114 28.991">
                <path id="go_down" data-name="go down" d="M23.369,27.991,14.507,17.082,5.644,27.991H0L11.369,14,0,0H5.644l8.863,10.909L23.369,0h5.644L17.644,14,29.013,27.991Z" transform="translate(1.05 0.5)" fill="#2e2e2e" stroke="rgba(112,112,112,0)" stroke-width="1" />
            </styles.CloseModalSvg>
        </styles.CloseModalButton>
    );
};

export default CloseModalButton;
