import React from 'react';
import styles from './styles';

const ArrowDownSingle = ({ open, toggle, messageOpen }) => {
    return (
        <styles.ContainerButton onClick={toggle}>
            {messageOpen}
            <styles.ContainerArrow open={open}>
                <svg xmlns="http://www.w3.org/2000/svg" width="41.987" height="17.014" viewBox="0 0 41.987 17.014">
                    <path id="go_dawn" data-name="go dawn" d="M362.006,2004.993,383,2016.362l20.994-11.369v5.644L383,2022.007l-20.993-11.37Z" transform="translate(-362.006 -2004.993)" fill="#c6c5c4" />
                </svg>
            </styles.ContainerArrow>
        </styles.ContainerButton>
    );
};

export default ArrowDownSingle;
