import React, {createRef, useEffect} from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";

const MiniGrid = ({ idx, onSelectOption, element, selected }) => {
    const imageRef = createRef();
    useEffect(() => {
        if (selected) {
            imageRef.current.scrollIntoView();
        }
    }, [selected]);
    return (
        <styles.Image ref={imageRef} onClick={() => onSelectOption(idx)} img={`${CMSAPI}${element.image.url}`} selected={selected} />
    );
};

export default MiniGrid;
