import {useState} from "react";

export default (gallery)=>{
    const [selectedIndex, setSelectedIndex] = useState(0);

    const leftUnavailable = selectedIndex - 1 < 0;
    const rightUnavailable = selectedIndex + 1 >= gallery?.length;
    
    const goLeft = ()=>{
        setSelectedIndex(leftUnavailable ? selectedIndex : selectedIndex - 1);
    }
    const goRight = ()=>{
        setSelectedIndex(rightUnavailable ? selectedIndex : selectedIndex + 1);
    }

    return {selectedIndex, setSelectedIndex, leftUnavailable, rightUnavailable, goLeft, goRight}
}
