import React, { useState, useEffect } from "react";
import styles from './styles.js';
import ArrowSimple from 'components/ArrowSimple';
import {getData} from 'helpers/getGallery';
import SimpleSidePanel from "components/SimpleSidePanel";
import Slider from 'components/Slider';
import ArrowRight from 'components/ArrowRight';

const DoorTypesTexts = [
    "Okucia do drzwi przymykowych",
    "Okucia do drzwi wahadowych",
    "Okucia do drzwi przesuwnowych",
    "Okucia do drzwi szkladanych",
];
const Mobile = ({doorTypeIndex}) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [okuciaTypow, setOkuciaTypow] = useState([]);
    const [elements, setElements] = useState([]);
    const [gallery, setGallery]= useState([]);
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    useEffect(() => {
        setSelectedIndex(0);
        (async () => {
            if(okuciaTypow?.length){
                const loadedItems = await getData(`${okuciaTypow[doorTypeIndex]?.elementsurl}`);
                console.log(loadedItems);
                setElements(loadedItems);
            }
        })();
    }, [okuciaTypow, doorTypeIndex]);


    useEffect(() => {
        (async () => {
            if(elements?.length){
                setGallery([]);
                const loadedItems = await getData(`${elements[selectedIndex]?.galleryurl}`);
                if(loadedItems){
                    setGallery(loadedItems);
                }
                
            }
        })();
    }, [elements, selectedIndex]);

    useEffect(() => {
        (async () => {
            const loadedItems = await getData('okucia-typows');
            setOkuciaTypow(loadedItems);
        })();
    }, []);


    if(doorTypeIndex === 3){
        return null;
    }

    return (
        <>
            <styles.TitleContainer onClick={toggle}><styles.Title><styles.Line open={open} /><ArrowSimple open={open} />{DoorTypesTexts[doorTypeIndex]}</styles.Title></styles.TitleContainer>
            <styles.DetailText expanded={expanded}>
                <styles.ContentDetailContainer>
                    <styles.DetailTitle>{elements?.length && elements[selectedIndex].titleshort}</styles.DetailTitle>
                    <styles.DetailDescription>
                        {elements?.length && elements[selectedIndex].description}
                    </styles.DetailDescription>
                </styles.ContentDetailContainer>
            </styles.DetailText>
            <styles.LeftBar expanded={expanded} open={open} onClick={toggleExpanded}>
                {open && <ArrowRight />}
            </styles.LeftBar>
            <styles.ContentContainer open={open}>
                <styles.SliderContainer>
                    <Slider gallery={gallery} />
                </styles.SliderContainer>
                {elements.length && <SimpleSidePanel title={"OKUCIA"} options={elements} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />}
            </styles.ContentContainer>
        </>
    );
};

export default Mobile;
