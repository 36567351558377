import styled from "styled-components";

const Svg = styled.svg`
  transform: rotate(${({open})=> open ? '90deg':'-90deg'});
  width: 50px;
  transition: all 0.3s;
`;

const Path = styled.path`
  
`;


export default {
  Svg,
  Path,
}
