import styled from "styled-components";

const ContainerArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #C6C5C4;
`;

const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #C6C5C4;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  margin-top: 170px;
  transition: all 0.3s;
  &:hover {
    color: #2e2e2e;
    & > div > svg > path{
      fill: #2e2e2e;
    }
  }
  & > div > svg > path{
    transition: all 0.3s;
  }
`;

const Arrow = styled.svg`
  ${({ open }) => open && 'transform: rotateX(180deg);'}
  transition: all 0.3s;
`;

const LineDown = styled.svg`
  ${({ open }) => open && 'margin-top: 80px;'}
  transition: all 0.3s;
`;

export default {
  ContainerArrow,
  ContainerButton,
  Arrow,
  LineDown,
}
