import React from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";

const HorizontalScroller = ({ gallery, selectedIndex, onSelectOption }) => {
    return (
        <styles.Container>
            {gallery?.map((element, idx) => <styles.Image onClick={() => onSelectOption(idx)} src={`${CMSAPI}${element.image.url}`} idx={idx} selectedIndex={selectedIndex} />)}
        </styles.Container>
    );
};

export default HorizontalScroller;
