import React from 'react';

const Chevron = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.709" height="18.353" viewBox="0 0 11.709 18.353">
            <path id="Path_2879" data-name="Path 2879" d="M3596.229-9750.131l7.412-6.932-7.412-7.18" transform="translate(-3594.108 9766.363)" fill="none" stroke="#3f3f3f" stroke-linecap="round" stroke-width="3" />
        </svg>
    );
};

export default Chevron;
