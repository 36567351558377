import styled from "styled-components";


const Container = styled.div`
  width: 100%;
`;

const ContainerImages = styled.div`
  width: 100%;
  position: relative;
  min-height: 400px;
  @media only screen and (min-width: 992px) {
    min-height: 500px;
  }
`;

const Image = styled.img`
  width: 120px;
  min-height: 270px;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 992px) {
    width: 200px;
    min-height: 450px;
  }

`;

const ContainerText = styled.p`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  white-space: normal;
  color: #3F3F3F;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  margin-top: 15px;
  @media only screen and (min-width: 992px) {
    margin-top: 10px;
    color: #3F3F3F;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
  }
`;

const ContainerDoor = styled.div`
  width: 120px;
  margin-right: 20px;
  position: absolute;
  top: 0px;
  left: ${({idx, selectedIdx})=> ((idx - selectedIdx) * 140)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.7s;
  @media only screen and (min-width: 992px) {
    width: 200px;
    margin-right: 40;
    position: absolute;
    top: 0px;
    left: ${({idx, selectedIdx})=> ((idx - selectedIdx) * 240)}px;
    min-height: 450px;
  }
`;

const UnderDoorText = styled.div`
  color: #3F3F3F;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  margin-top: 15px;
  @media only screen and (min-width: 992px) {
    margin-top: 10px;
    color: #3F3F3F;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
  }
`;

const ContainerSequencer = styled.div`
  min-width: 300px;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: -6px;
  margin-bottom: 7px;
`;

const ContainerSequencerElement = styled.div`
width: 20px;
height: 20px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
  &: hover{
    & > svg > path{
      stroke: #707070;
      transition: all 0.2s;
    }
    & > div {
      background-color: #707070;
    }
  }
`;

const MiddleDot = styled.div`
  width:5px;
  height:5px;
  border-radius:5px;
  border: 1px solid #707070;
  ${({selected})=>selected && 'background-color: #707070;'}
  transition: all 0.7s;
`;

export default {
  Container,
  ContainerImages,
  Image,
  ContainerSequencer,
  MiddleDot,
  ContainerSequencerElement,
  ContainerDoor,
  UnderDoorText,
  ContainerText,
}