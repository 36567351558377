import styled from "styled-components";

const ContainerSequencerElement = styled.div`
height: 17px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
&:first-child{
  transform: rotate(180deg);
}
& > svg > path {
  ${({disabled})=> disabled ? 'fill: #707070;' : 'fill: #eeeeee;'}
}
  &: hover{
    & > svg > path {
      fill: #707070;
      transition: all 0.2s;
    }
  }
`;

const TextContainer = styled.div`
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  color: #000000;
  width: 45px;
  text-align: center;
`;


export default {
  ContainerSequencerElement,
  TextContainer,
}