import React from "react";

const ArrowRight = () => {
    return (

        <svg id="strzaleczka" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect id="bg_kropeczka" data-name="bg kropeczka" width="20" height="20" fill="#fff" opacity="0.001" />
            <path id="strzaleczka-2" data-name="strzaleczka" d="M8.459,3.541c1.4,1.871,4.271,5.551,4.271,5.551l-4.112,5.7" transform="translate(0.309 0.477)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="3" />
            <path id="strzaleczka-3" data-name="strzaleczka" d="M8.459,3.541c1.4,1.871,4.271,5.551,4.271,5.551l-4.112,5.7" transform="translate(0.309 0.477)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
        </svg>


    );
};

export default ArrowRight;
