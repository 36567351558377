import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from 'ModalContext';
import Hero from './Hero';
import Interiors from './Interiors';
import Doors from './Doors';
import Modal from './Modal';
import Patterns from './Patterns';
import Catalog from './Catalog';
import Handlers from './Handlers';
import Frames from './Frames';
import Alert from './Alert';
import GlassTypes from './GlassTypes';
import scrollToComponent from 'react-scroll-to-component';
import ButtonTop from 'components/ButtonTop';
import DetectMobile from 'helpers/DetectMobile.js';
import {
    useLocation
} from "react-router-dom";
import styledComponents from './styles';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Landing = () => {
    const { modalShown } = useContext(ModalContext);
    const [handlersOpen, setHandlersOpen] = useState(false);
    const [framesOpen, setFramesOpen] = useState(false);
    const [doorTypeIndex, setDoorTypeIndex] = useState(0);
    let offsetTop = -65;
    if(DetectMobile()){
      offsetTop = 0;
    }
    const toggleFrames = () => {
        if(!framesOpen){
            scrollToComponent(framesRef.current, { align: 'top', offset: offsetTop });
        }else{
            scrollToComponent(handlersRef.current, { align: 'top', offset: offsetTop });
        }
        setFramesOpen(!framesOpen);
    }
    const toggleHandlers = () => {
        if(!handlersOpen){
            scrollToComponent(handlersRef.current, { align: 'top', offset: offsetTop });
        }else{
            scrollToComponent(doorsRef.current, { align: 'top', offset: offsetTop });
        }
        setHandlersOpen(!handlersOpen);
        setFramesOpen(false);
    }
    const updateDoorType = (idx) => {
        setDoorTypeIndex(idx)
    };

    let topRef = React.createRef();
    let interiorsRef = React.createRef();
    let doorsRef = React.createRef();
    let handlersRef = React.createRef();
    let patternsRef = React.createRef();
    let glassRef = React.createRef();
    let framesRef = React.createRef();
    let footerRef = React.createRef();
    let query = useQuery().get('section');
    let queryType = useQuery().get('type');

    useEffect(() => {
        if (queryType !== 'scrolled') {
            switch (query) {
                case 'start':
                    scrollToComponent(topRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'interiors':
                    scrollToComponent(interiorsRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'doors':
                    scrollToComponent(doorsRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'handlers':
                    scrollToComponent(handlersRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'frames':
                    scrollToComponent(framesRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'patterns':
                    scrollToComponent(patternsRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'glasses':
                    scrollToComponent(glassRef.current, { align: 'top', offset: offsetTop });
                    break;
                case 'contact':
                    scrollToComponent(footerRef.current, { align: 'top', offset: offsetTop });
                    break;
                default:
                    scrollToComponent(topRef.current, { align: 'top', offset: offsetTop });
                    break;
            }
        }
    }, [query, queryType, interiorsRef]);

    const scrollToTop = () => {
        scrollToComponent(topRef.current, { align: 'top', offset: offsetTop });
    }

    return (
        <>
            {modalShown && <Modal />}
            <Hero ref={topRef} />
            {/* <Alert /> */}
            <Interiors ref={interiorsRef} />
            <Doors ref={doorsRef} toggleHandlers={toggleHandlers} handlersOpen={handlersOpen} updateDoorTypeParent={updateDoorType} />
            <Handlers ref={handlersRef} toggleFrames={toggleFrames} open={handlersOpen} framesOpen={framesOpen} doorTypeIndex={doorTypeIndex} />
            <Frames ref={framesRef} open={framesOpen} />
            <Patterns ref={patternsRef} />
            <GlassTypes ref={glassRef} />
            <Catalog />
            <ButtonTop handleClick={scrollToTop}/>
            <styledComponents.FooterUp ref={footerRef} />
        </>
    );
};

export default Landing;
