import React, {useContext} from "react";
import styles from './styles';
import Clip from 'media/images/BgFooter@2x.png';
import ClipMobile from 'media/images/jacob-plumb-UghHZmnJw58-unsplash@2x.png';
import { useHistory } from "react-router";
import { ModalContext } from 'ModalContext';
const Footer = () => {
  const history = useHistory();
  const { modalShown } = useContext(ModalContext);
  if(!modalShown){
    return (
      <styles.ContainerFooter>
        <styles.ContainerMoto>
          <styles.Line />
          <styles.Button onClick={()=>history.push('/locations')}>lista sprzedawców</styles.Button>
            <styles.Text>
              Producentem szklanych drzwi jest firma Gipsy Kings, która jest największym w Polsce producentem standardowych drzwi całoszklanych
              i przedstawicielem firmy DORMA
              w zakresie okuć do stolarki szklanej. Niekończące się możliwości kreowania przestrzeni za pomocą szkła hartowanego możliwe są do uzyskania dzięki wieloletniemu doświadczeniu. Posiadamy dobrze rozwiniętą sieć przedstawicieli i montażystów oraz możliwość zaprojektowania, realizacji
              i obsługi dowolnego zlecenia. Produkty firmy DORMA stosowane przez GIPSY KINGS są przykładem niezawodności
              i długiej żywotności, a jej gwarancją  DIN EN ISO 9002.
            </styles.Text>
            <styles.DataMobile>
              <br /><br /><br />
              ADRES<br />
              44-100 Gliwice, ul. Witkiewicza 16
              <br /><br />
              TELEFONY<br />
              tel. <a href = "tel:+48322382638">32 238 26 38</a>,<a href = "tel:+48322312622"> 32 231 26 22</a><br />
              fax 32 231 45 08
              <br /><br />
              EMAIL<br />
              <a href = "mailto: gliwice@szklanedrzwi.pl">gliwice@szklanedrzwi.pl</a>
              <br /><br /><br />
            </styles.DataMobile>
        </styles.ContainerMoto>
        <styles.ImageFooter image={Clip} imageMobile={ClipMobile}>
          <styles.TextOverImage>
            ADRES<br />
            44-100 Gliwice, <br />
            ul. Witkiewicza 16<br />
            tel. <a href = "tel:+48322382638">32 238 26 38</a>,<a href = "tel:+48322312622"> 32 231 26 22</a><br />
            fax 32 231 45 08<br />
            e-mail: <a href = "mailto: gliwice@szklanedrzwi.pl">gliwice@szklanedrzwi.pl</a><br />
          </styles.TextOverImage>
          <styles.LineImage />
        </styles.ImageFooter>
      </styles.ContainerFooter>
    );
  }else{
    return null;
  }
  
};

export default Footer;
