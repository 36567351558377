import React from "react";
import styles from './styles.js';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Frames = React.forwardRef(({open}, ref) => {
    return (
        <styles.FramesContainer open={open} ref={ref}>
            <Mobile />
            <Desktop />
        </styles.FramesContainer>
    );
});

export default Frames;
