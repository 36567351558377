import styled from "styled-components";

const ListContainer = styled.div`
  width: 407px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    ${({hiddenDesktop})=> hiddenDesktop && "display: none;"}
  }
`;

const ListTitle  = styled.div`
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 48px;
  line-height: 57.6px;
  color: #3F3F3F;
  margin-top: 90px;
  margin-bottom: 15px;
`;

const ListElement = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 36px;
  line-height: 70px;
  color: #C6C5C4;
  border-bottom: 1px solid #3F3F3F;
  cursor: pointer;
  box-sizing: border-box;
  & div > svg > path{
    stroke: transparent;
    transition: all 0.2s;
  }
  &:hover{
    color: #3F3F3F;
    border-bottom: 3px solid #3F3F3F;
    & div > svg > path{
      stroke: #3F3F3F;
    }
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 0px;
`;

export default {
  ListContainer,
  ListElement,
  ListTitle,
  ArrowContainer,
}