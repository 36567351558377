import React from 'react';
import styles from './styles';

const ArrowDownOpen = ({ open, toggle, messageOpen }) => {
    return (
        <styles.ContainerButton onClick={toggle} open={open}>
            {messageOpen}
            <styles.ContainerArrow open={open}>
                <styles.Arrow open={open} xmlns="http://www.w3.org/2000/svg" width="41.987" height="17.014" viewBox="0 0 41.987 17.014">
                    <path id="go_dawn" data-name="go dawn" d="M362.006,2004.993,383,2016.362l20.994-11.369v5.644L383,2022.007l-20.993-11.37Z" transform="translate(-362.006 -2004.993)" fill="#c6c5c4" />
                </styles.Arrow>
                <styles.LineDown open={open} xmlns="http://www.w3.org/2000/svg" width="42" height="5" viewBox="0 0 42 5">
                    <line id="Line_8" data-name="Line 8" x2="42" transform="translate(0 2.5)" fill="none" stroke="#2e2e2e" stroke-width="5" />
                </styles.LineDown>
            </styles.ContainerArrow>
        </styles.ContainerButton>
    );
};

export default ArrowDownOpen;
