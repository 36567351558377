import styled from "styled-components";


const Container = styled.div`
  width: 100%;
`;

const ContainerImages = styled.div`
  width: 100%;
  height: 325px;
  position: relative;
  overflow: hidden;
`;

const Image = styled.div`
  width: 100%;
  height: 325px;
  position: absolute;
  top: 0px;
  left: ${({idx, selectedIdx})=> ((idx - selectedIdx) * 100)}%;
  background-image: ${({img})=> `url(${img})`};
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.7s;
`;


const ContainerSequencer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default {
  Container,
  ContainerImages,
  Image,
  ContainerSequencer,
}