import React, { useState, useEffect, useContext } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import Gallery from "components/Gallery";
import SidePanel from "components/SidePanel";
import Showcase from "./Showcase";
import { useHistory } from "react-router";
import { ModalContext } from "ModalContext";
import { useParams } from "react-router-dom";
import CMSAPI from "cmsapi";
import GridSvg from "components/GridSvg";
import Sequencer from './Sequencer';

const Patterns = () => {
    const [gallery, setGallery] = useState([]);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const { patternId } = useParams();
    const { toggleModal } = useContext(ModalContext);
    const history= useHistory();

    useEffect(() => {
        (async () => {
            if (patternId) {
                const loadedGallery = await getData(`${patternId}`);
                setGallery(loadedGallery);
            }
        })();
    }, [patternId]);
    
    const updateIndex = (idx) => {
        setGalleryIndex(idx);
    }

    useEffect(() => {
        toggleModal();
    }, [])

    const returnToPatterns= ()=>{
        history.push(`/landing?section=patterns`);
        toggleModal();
    }

    return (
        <styles.ModalContainer>
            <styles.NavbarModal>
                <styles.ContainerListButton  onClick={returnToPatterns}><GridSvg/></styles.ContainerListButton>
            </styles.NavbarModal>
            <styles.ContainerMenu>
                <SidePanel title={"WZORY"} options={[]} />
                <styles.ContainerDoor>
                    <styles.TextOverDoor>{!!gallery?.length && gallery[galleryIndex].description}</styles.TextOverDoor>
                    {!!gallery?.length && <styles.Door src={`${CMSAPI}${gallery[galleryIndex].door.url}`} alt={gallery?.length && gallery[galleryIndex].name}></styles.Door>}
                </styles.ContainerDoor>
            </styles.ContainerMenu>
            <styles.ContainerGallery>
                <Gallery gallery={gallery} updateParentIndex={updateIndex} parentIndex={galleryIndex}/>
            </styles.ContainerGallery>
            <styles.TitlePhone>{!!gallery?.length && gallery[galleryIndex].name}</styles.TitlePhone>
            <styles.ShowCaseContainer><Showcase gallery={gallery} initialIndex={0} updateParentIndex={updateIndex} /></styles.ShowCaseContainer>
            <styles.LabelContainer>
                <Sequencer updateIndex={updateIndex} selectedIndex={galleryIndex} galleryLength={gallery?.length}/>
            </styles.LabelContainer>
        </styles.ModalContainer>
    );
};


export default Patterns;
