import styled from "styled-components";

const ModalContainer = styled.div`
  width: 100vw;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px
  background-color: #DCDBD9;
  z-index: 50;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 90px;
  text-align: right;
  @media only screen and (max-width: 992px) {
    background-color:  ${({fullModal})=> fullModal ? 'black':'#3F3F3F'};
    align-items: center;
    padding-top: 0px;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const ShowCaseContainer = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const NavbarModal = styled.div`
  width: 100vw;
  height: 81px;
  position: fixed;
  top: 0px;
  left: 0px
  z-index: 200;
  background-color: ${({fullModal})=> fullModal ? 'black' :'#c2c2c2'};
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 992px) {
    background-color: white;
    padding-right: 20px;
  }
`;

const CloseModalButtonContainer = styled.div`
  width: 1100px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ContainerListButton = styled.div`
  position: absolute;
  left: 35px;
  cursor: pointer;
  z-index: 200;
  &:hover {
    & >svg>g>line{
      stroke: #2f2f2f;
    }
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerMenu = styled.div`
  margin-top: 60px;
  width: 388px;
  margin-right: 60px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ContainerGallery = styled.div`
  width: 825px;
  min-width: 60%;
  margin-top: 50px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;


const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const TitlePhoneGroup = styled.div`
  z-index: 300;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 36px;
  top: 10px;
  color: #FFFFFF;
  text-transform: uppercase;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const TitlePhone = styled.div`
  margin-top: 110px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 36px;
  color: #C6C5C4;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export default {
  ModalContainer,
  ContainerMenu,
  ContainerGallery,
  GridContainer,
  TitlePhone,
  NavbarModal,
  ContainerListButton,
  ShowCaseContainer,
  CloseModalButtonContainer,
  TitlePhoneGroup,
}