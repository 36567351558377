import React from "react";
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import styles from './styles.js';

const ArrowsGallery = ({leftUnavailable, rightUnavailable, goLeft, goRight}) => {
    return (
        <styles.ContainerArrows>
            <styles.ArrowContainer unavailable={leftUnavailable} onClick={goLeft}><ArrowLeft/></styles.ArrowContainer>
            <styles.ArrowContainer unavailable={rightUnavailable} onClick={goRight}><ArrowRight/></styles.ArrowContainer>
        </styles.ContainerArrows>
    );
};

export default ArrowsGallery;
