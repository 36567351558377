import React, { useState, useEffect } from "react";
import styles from './styles.js';
import ArrowSimple from 'components/ArrowSimple';
import { getData } from 'helpers/getGallery';
import SimpleSidePanel from "components/SimpleSidePanel";
import Slider from 'components/Slider';
import ArrowRight from 'components/ArrowRight';

const Mobile = () => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [gallery, setGallery] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [types, setTypes] = useState([]);

    const toggle = () => {
        setOpen(!open);
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }



    useEffect(() => {
        (async () => {
            const loadedTypes = await getData('typy-oscieznices');
            setTypes(loadedTypes);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (types?.length) {
                const loadedGallery = await getData(`${types[selectedIndex].galleryurl}`);
                setGallery(loadedGallery);
            }
        })();
    }, [types, selectedIndex]);


    return (
        <>
            <styles.TitleContainer onClick={toggle}><styles.Title><styles.Line open={open} /><ArrowSimple open={open} />Ościeżnice</styles.Title></styles.TitleContainer>
            <styles.DetailText expanded={expanded}>
                <styles.ContentDetailContainer>
                    <styles.DetailTitle>{types?.length && types[selectedIndex].titleshort}</styles.DetailTitle>
                    <styles.DetailDescription>
                        {types?.length && types[selectedIndex].description}
                    </styles.DetailDescription>
                </styles.ContentDetailContainer>
            </styles.DetailText>
            <styles.LeftBar expanded={expanded} open={open} onClick={toggleExpanded}>
                {open && <ArrowRight />}
            </styles.LeftBar>
            <styles.ContentContainer open={open}>
                <styles.SliderContainer>
                    <Slider gallery={gallery} />
                </styles.SliderContainer>
                <SimpleSidePanel title={"OKUCIA"} options={types} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
            </styles.ContentContainer>
        </>
    );
};

export default Mobile;
