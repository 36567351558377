import styled from "styled-components";

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  white-space: nowrap;
`;

export default {
  SliderContainer,
}