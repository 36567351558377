import React from "react";
import styles from './styles.js';
import ElementPanel from './ElementPanel';

const SidePanel = ({ title, options, onSelectOption, selectedIndex }) => {
    return (
        <styles.ContainerMenu>
            {title && <styles.Title>{title}</styles.Title>}
            {options?.map((option, idx) => {
                return <ElementPanel idx={idx} selected={selectedIndex === idx} onSelectOption={onSelectOption} option={option}/>
            })}
        </styles.ContainerMenu>
    );
};

export default SidePanel;