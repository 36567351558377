import styled from "styled-components";


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 0px;
`;

const ContainerAlert = styled.div`
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1080px;
  }
  position: relative;
  height: 0px;
`;

const Title = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-style: bold;
  font-size: 36px;
`;

const Body = styled.div`
  margin-top: 15px;
  font-size: 19px;
  font-family: 'Open Sans', sans-serif;
  color: #2e2e2e;
`;

const Alert = styled.div`
  width: 465px;
  background-color: #c6e86b;
  position: absolute;
  top: -150px;
  left: 10px;
  border: 1px solid #707070;
  filter: drop-shadow(7px 7px 13px rgba(0,0,0,0.5));
  z-index: 2000;
  padding: 10px 20px 10px 30px;
  box-sizing: border-box; 
  @media only screen and (max-width: 600px) {
    top: -200px;
    width: 100%;
  }
  opacity: ${({ visible }) => visible ? "1" : "0"};
  transition: opacity 2s;
`;

const Close = styled.div`
  background-color: #c6e86b;
  border-radius: 50%;
  border: 4px solid white;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  top: -40px;
  cursor: pointer;
  >svg{
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.4));
  }
  &:hover{
    >svg>path{
      fill: #9e9e9e;
    }
  }
`;

export default {
  Container,
  ContainerAlert,
  Alert,
  Title,
  Body,
  Close,
}