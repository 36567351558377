import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./pages/Landing";
import Interiors from "pages/Interiors";
import Patterns from "./pages/Patterns";
import Products from "./pages/Products";
import Locations from "./pages/Locations";

import { ModalProvider } from "./ModalContext";
import Styles from './styles/Macro'

const Main = () => {
  return (
    <ModalProvider>
      <HashRouter>
        <Styles.AppContainer>
          <Header />
          <Switch>
            <Route path="/landing" component={Landing} />
            <Route path="/interiors/:interiorId" component={Interiors}/>
            <Route path="/patterns/:patternId" component={Patterns}/>
            <Route path="/products" component={Products}/>
            <Route path="/locations" component={Locations}/>
            <Route exact path="/" component={Landing}>
              <Redirect to="/landing" />
            </Route>
          </Switch>
          <Footer/>
        </Styles.AppContainer>
      </HashRouter>
    </ModalProvider>
  );
};

export default Main;
