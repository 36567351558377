import React, { useState } from "react";
import styles from './styles.js';
import CloseModalButton from 'components/Header/CloseModalButton/index';

const Alert = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(true);
    const handleClosePopUp = ()=>{
        setVisible(false);
    }

    return (
        <styles.Container>
            <styles.ContainerAlert>
                <styles.Alert visible={visible}>
                    <styles.Close onClick={handleClosePopUp}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.114" height="28.991" viewBox="0 0 31.114 28.991">
                            <path id="go_down" data-name="go down" d="M23.369,27.991,14.507,17.082,5.644,27.991H0L11.369,14,0,0H5.644l8.863,10.909L23.369,0h5.644L17.644,14,29.013,27.991Z" transform="translate(1.05 0.5)" fill="white" stroke="rgba(255,255,255,0)" stroke-width="1" />
                        </svg>
                    </styles.Close>
                    <styles.Title>Nowy cennik</styles.Title>
                    <styles.Body>
                        W związku z&nbsp;nieprzewidywalnym wzrostem cen surowców, wciąż zwiększającym się kursem walut oraz kosztem transportu, zmuszeni jesteśmy do wprowadzenia podwyżki cen naszych produktów.
                        W tej chwili oczekujemy na informacje
                        od naszych Dostawców, jak wysokie
                        będą to podwyżki, ale&nbsp;<b>należy się liczyć ze wzrostem nie mniejszym niż 10%.
                            Nowy cennik zostanie opublikowany najpóźniej w&nbsp;połowie kwietnia.</b>
                        <br /><br />
                        Prosimy wziąć te informacje pod uwagę przy swoich kalkulacjach
                        oraz rozmowach z&nbsp;Klientami.
                    </styles.Body>
                </styles.Alert>
            </styles.ContainerAlert>
        </styles.Container>
    );
});

export default Alert;
