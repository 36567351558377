import React from 'react';

export const DoorA = () => {
    return (
        <svg id="Btn_rodzDrzwi" data-name="Btn rodzDrzwi" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
            <g id="Bg_rodzDrzwi" data-name="Bg rodzDrzwi" fill="#eae8e5" stroke="#909090" stroke-width="1">
                <rect width="130" height="130" rx="16" stroke="none" />
                <rect x="0.5" y="0.5" width="129" height="129" rx="15.5" fill="none" />
            </g>
            <g id="przymyk">
                <path id="uchyl" d="M52.192,58.533l-1.158-.5a1.33,1.33,0,0,1,.55-2.547h.608V18.345H38.8v83.579H0V99.266H36.134V15.688H52.192V0l41.48,13.066v86.2h34.8v2.658h-34.8v1.588l-41.48,8.947Zm6.692,45.693,28.093-6.06V17.925L58.884,9.062Zm11.273-44.1L63.9,58.4H61.523a1.856,1.856,0,1,1,0-3.711l3.126.055,6.516,1.79a1.861,1.861,0,0,1-.506,3.656A2,2,0,0,1,70.156,60.125Z" fill="#3f3f3f" />
            </g>
        </svg>

    );
};

export const DoorB = () => {
    return (
        <svg id="Btn_rodzDrzwi" data-name="Btn rodzDrzwi" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
            <g id="Bg_rodzDrzwi" data-name="Bg rodzDrzwi" fill="#eae8e5" stroke="#909090" stroke-width="1">
                <rect width="130" height="130" rx="16" stroke="none" />
                <rect x="0.5" y="0.5" width="129" height="129" rx="15.5" fill="none" />
            </g>
            <g id="wahadlo2" transform="translate(0.732 4.408)">
                <path id="Union_1" data-name="Union 1" d="M72.757,95.026,55.565,86.756V22.213l17.191-5.72V14.351H37.049V99H0V96.356H34.4V11.706H72.757V0L91.276,11.894V96.356h37.039V99H91.276v1.555L72.757,114.414Zm6.612,5.98,5.3-3.951V15.488l-5.3-3.39ZM60.85,26.031v57.4l11.907,5.725V22.08Zm7.719,86.15c-.992-.1-1.746-.775-1.67-1.519s.936-1.254,1.937-1.162,1.746.78,1.68,1.519a1.565,1.565,0,0,1-1.7,1.173A2.027,2.027,0,0,1,68.568,112.181Zm-5.69-.484c-.464-.056-.915-.112-1.361-.168a1.325,1.325,0,0,1,.359-2.626l1.295.168a1.322,1.322,0,0,1-.148,2.636C62.981,111.707,62.922,111.7,62.879,111.7Zm-6.758-1.229c-.451-.122-.892-.24-1.328-.367a1.341,1.341,0,0,1-.9-1.652,1.325,1.325,0,0,1,1.642-.887c.408.112.823.235,1.252.336a1.332,1.332,0,0,1,.946,1.611,1.314,1.314,0,0,1-1.272.994A1.5,1.5,0,0,1,56.121,110.468ZM49.6,108.159c-.431-.2-.844-.4-1.252-.617a1.324,1.324,0,1,1,1.229-2.345c.372.194.744.382,1.137.561a1.323,1.323,0,0,1-.563,2.524A1.337,1.337,0,0,1,49.6,108.159Zm-5.937-3.829c-.37-.342-.711-.688-1.027-1.035a1.321,1.321,0,1,1,1.963-1.769c.257.28.551.576.857.856a1.323,1.323,0,1,1-1.792,1.948Zm-3.627-6.556a3.282,3.282,0,0,1-.023-.474,9.133,9.133,0,0,1,.066-1.117,1.3,1.3,0,0,1,1.468-1.147,1.317,1.317,0,0,1,1.16,1.463,6.26,6.26,0,0,0-.046.8,2.1,2.1,0,0,0,.013.28,1.33,1.33,0,0,1-1.229,1.412.306.306,0,0,1-.092.01A1.31,1.31,0,0,1,40.034,97.774Zm2.985-5.068a1.34,1.34,0,0,1,.214-1.861c.375-.28.767-.576,1.206-.841a1.308,1.308,0,0,1,1.825.4,1.321,1.321,0,0,1-.405,1.825q-.528.336-.981.688a1.257,1.257,0,0,1-.811.291A1.367,1.367,0,0,1,43.019,92.706Zm5.97-4.859a1.545,1.545,0,0,1,2.975-.495A1.392,1.392,0,0,1,50.669,88.8a1.624,1.624,0,0,1-.308.025A1.293,1.293,0,0,1,48.989,87.847Z" fill="#909090" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
            </g>
        </svg>

    );
};

export const DoorC = () => {
    return (
        <svg id="Btn_rodzDrzwi" data-name="Btn rodzDrzwi" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
            <g id="Bg_rodzDrzwi" data-name="Bg rodzDrzwi" fill="#eae8e5" stroke="#909090" stroke-width="1">
                <rect width="130" height="130" rx="16" stroke="none" />
                <rect x="0.5" y="0.5" width="129" height="129" rx="15.5" fill="none" />
            </g>
            <g id="przesuw" transform="translate(0.734 6.404)">
                <path id="przesuw-2" data-name="przesuw" d="M-243.191,130.769V52.635h12.044V135.11h52.191v-4.341h25.427v-2.894h-25.427V39.612h9.368a1.4,1.4,0,0,0,1.338-1.447,1.4,1.4,0,0,0-1.338-1.447H-189.21a9.065,9.065,0,0,0,2.829-6.73c0-4.652-3.491-8.546-7.789-8.546s-7.791,3.954-7.791,8.606a8.927,8.927,0,0,0,2.829,6.669h-11.49a9.065,9.065,0,0,0,2.829-6.73c0-4.652-3.491-8.546-7.789-8.546s-7.791,3.954-7.791,8.606a8.927,8.927,0,0,0,2.829,6.669H-264.6a1.4,1.4,0,0,0-1.338,1.447,1.394,1.394,0,0,0,1.338,1.447h33.456V49.741h-14.721v78.134H-282v2.894Zm46.838-100.322a2.094,2.094,0,0,1,2.007-2.17,2.094,2.094,0,0,1,2.007,2.17V44.917a2.094,2.094,0,0,1-2.007,2.17,2.094,2.094,0,0,1-2.007-2.17Zm-21.412,0a2.094,2.094,0,0,1,2.007-2.17,2.094,2.094,0,0,1,2.007,2.17V44.917a2.094,2.094,0,0,1-2.007,2.17,2.094,2.094,0,0,1-2.007-2.17ZM-224.456,45.4h4.015a4.825,4.825,0,0,0,4.684,5.064,4.825,4.825,0,0,0,4.684-5.064h12.044a4.825,4.825,0,0,0,4.684,5.064,4.825,4.825,0,0,0,4.684-5.064h4.015v82.475h-38.809Z" transform="translate(282 -21.443)" fill="#909090" />
            </g>
        </svg>

    );
};


export const DoorD = () => {
    return (
        <svg id="Btn_rodzDrzwi" data-name="Btn rodzDrzwi" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
            <g id="Bg_rodzDrzwi" data-name="Bg rodzDrzwi" fill="#eae8e5" stroke="#909090" stroke-width="1">
                <rect width="130" height="130" rx="16" stroke="none" />
                <rect x="0.5" y="0.5" width="129" height="129" rx="15.5" fill="none" />
            </g>
            <g id="skladane" transform="translate(0.734 6.404)">
                <path id="lamane" d="M52.013,59.771l-1.146-.5a1.335,1.335,0,0,1,.554-2.553h.592V19.449H37.349V103.3H0v-2.659H34.675V16.791H52.013V0L75.366,17.148,93.372,12.1v88.547h34.675V103.3H93.372v2.984l-18.159-4.732-23.2,13.92Zm6.683,43.94L73.361,94.9V23.93L58.7,13.156ZM76.034,23.865V94.881L86.689,97.67V20.889Zm-7.2,39.5L64.368,62.08H62.687a1.387,1.387,0,0,1,0-2.773l2.215.041,4.64,1.333a1.385,1.385,0,0,1,.916,1.708,1.317,1.317,0,0,1-1.279,1.016A1.645,1.645,0,0,1,68.836,63.365Z" fill="#909090" />
            </g>
        </svg>
    );
};

const DoorsSvg = ({ svgname }) => {
    switch (svgname) {
        case "przymykowe":
            return <DoorA />
        case "wahadlowe":
            return <DoorB />
        case "przesuwne":
            return <DoorC />
        case "skladane":
            return <DoorD />
        default:
            return <DoorA />

    }
}

export default DoorsSvg;