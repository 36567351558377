import React, { useState } from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import Sequencer from "./Sequencer";

const Showcase = ({ gallery, initialIndex, updateParentIndex}) => {

    const [selectedIndex, setSelectedIndex] = useState(initialIndex);

    const updateIndex = (idx)=>{
        setSelectedIndex(idx);
        console.log(idx);
        updateParentIndex(idx);
    }

    return (
        <styles.Container>
            <styles.ContainerImages>
                {gallery?.map((element, idx) => <styles.Image img={`${CMSAPI}${element.image.url}`} idx={idx} selectedIdx={selectedIndex} />)}
            </styles.ContainerImages>
            <styles.ContainerSequencer>
                <Sequencer updateIndex={updateIndex} selectedIndex={selectedIndex} galleryLength={gallery?.length}/>
            </styles.ContainerSequencer>
        </styles.Container>
    );
};

export default Showcase;
