import styled from "styled-components";

const ContainerArrows = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 686px;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  @media only screen and (min-width: 992px) {
    max-width: 505px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 686px;
  }

`;

const ArrowContainer = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  cursor: pointer;
  ${({unavailable})=> unavailable && 'visibility: hidden;'}
  &>svg>g>path{
    fill: rgba(255,255,255,0.0);
    transition: all 0.3s;
  }
  &:hover{
    &>svg>g>path{
      fill: #ffffff;
    }
  }
`;

export default {
  ContainerArrows,
  ArrowContainer,
}