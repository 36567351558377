import React from "react";

const ArrowLeft = () => {
    return (
        <svg id="strzaleczka_L" data-name="strzaleczka L" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect id="bg_kropeczka" data-name="bg kropeczka" width="20" height="20" fill="#fff" opacity="0.001" />
            <path id="strzaleczka" d="M12.73,3.541c-1.4,1.871-4.271,5.551-4.271,5.551l4.112,5.7" transform="translate(0.309 0.477)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="3" />
            <path id="strzaleczka-2" data-name="strzaleczka" d="M12.73,3.541c-1.4,1.871-4.271,5.551-4.271,5.551l4.112,5.7" transform="translate(0.309 0.477)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
        </svg>

    );
};

export default ArrowLeft;
