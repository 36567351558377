import styled from "styled-components";


const Container = styled.div`
  padding-top: 7.5px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-contenet: flex-start;
  flex-wrap: wrap;
  overflow: scroll;

  @media only screen and (max-width: 992px) {
    border-right: 0;
  }
`;

const Image = styled.div`
  width: 75px;
  height: 75px;
  background-image: ${({img})=> `url(${img})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 7.5px solid ${({selected})=> selected ? "#2E2E2E":"#FFFFFF"};
  transition: all 0.7s;
  flex-shrink: 0;
  cursor: pointer;
`;


export default {
  Container,
  Image,
}