import React from 'react';
import styles from './styles';

const ArrowSimple = ({ open }) => {
    return (
        <styles.Svg open={open} xmlns="http://www.w3.org/2000/svg" width="17.014" height="33.967" viewBox="0 0 17.014 33.967">
            <styles.Path id="go_Right" data-name="go Right" d="M0,0,16.983,11.369,33.967,0V5.644L16.983,17.014,0,5.644Z" transform="translate(17.014) rotate(90)" fill="#c6c5c4" />
        </styles.Svg>
    );
};

export default ArrowSimple;
