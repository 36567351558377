import React from "react";

const ArrowRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.067" height="35.05" viewBox="0 0 23.067 35.05">
        <g id="Group_193" data-name="Group 193" transform="translate(3.534 3.517)">
            <line id="Line_13" data-name="Line 13" x2="16" y2="13" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="5"/>
            <line id="Line_15" data-name="Line 15" y1="15" x2="16" transform="translate(0 13)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="5"/>
        </g>
        </svg>
    );
};

export default ArrowRight;



