import styled from "styled-components";


const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  cursor: pointer;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin: 7.5px;
  background-color: ${({selected})=> selected ? "#2E2E2E":"#FFFFFF"};
  border: 1px solid #2E2E2E;
  flex-shrink: 0;
`;

export default {
  Container,
  Dot,
}