import React, { useState, useEffect, useContext } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import SidePanel from "./SidePanel";
import MiniGrid from "./MiniGrid";
import DotGrid from "./DotGrid";
import { useHistory } from "react-router";
import { ModalContext } from "ModalContext";
import CloseModalButton from './CloseModalButton';
import CMSAPI from "cmsapi";
import HorizontalScroller from './HorizontalScroller';

const Modal = () => {
    const history = useHistory();
    const [productsIndex, setProductsIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsFiltered, setFilteredProducts] = useState([]);
    const { toggleModal } = useContext(ModalContext);

    useEffect(() => {
        toggleModal();
        (async () => {
            const loadedProducts = await getData('produkties');
            const loadedProducts2 = await getData('produkty-2-s');
            const allLoadedProducts = loadedProducts.concat(loadedProducts2);
            console.log(allLoadedProducts);
            setProducts(allLoadedProducts);
            setFilteredProducts(allLoadedProducts);
        })();
    }, []);

    const updateIndex = (idx) => {
        setProductsIndex(idx);
    };

    const backToLanding = () => {
        toggleModal();
        history.push(`/landing`)
    };

    const handleSearch = (e) =>{
        setProductsIndex(0);
        const newProductsFiltered = products.filter((product)=>{
            return product.name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setFilteredProducts(newProductsFiltered)
    }

    return (
        <styles.ModalContainer>
            <styles.NavbarModal>
                <styles.CloseModalButtonContainer>
                    <styles.SearchInput onChange={handleSearch}/>
                    <CloseModalButton toggleModal={backToLanding} />
                </styles.CloseModalButtonContainer>
            </styles.NavbarModal>
            <styles.ContainerUpperPart>
                <styles.ContainerLeft>
                    <styles.TitleContainer>
                        Wszystkie produkty
                    </styles.TitleContainer>
                </styles.ContainerLeft>
                <styles.ContainerRight>
                    Numer i nazwa Produktu
                </styles.ContainerRight>
            </styles.ContainerUpperPart>
            <styles.ContainerGallery>
                <styles.ContainerDoorImage>
                    <styles.DoorLine />
                {productsFiltered?.length && 
                <>
                    <styles.DoorImage src={`${CMSAPI}${productsFiltered[productsIndex]?.image.url}`} />
                    <styles.DoorText>{productsFiltered[productsIndex]?.name}</styles.DoorText>
                </>
                }
                </styles.ContainerDoorImage>
                <styles.ContainerMiniGrid>
                    <MiniGrid gallery={productsFiltered} selectedIndex={productsIndex} onSelectOption={(idx) => updateIndex(idx)} />
                </styles.ContainerMiniGrid>
                <styles.ContainerDotGrid>
                    <DotGrid gallery={productsFiltered} selectedIndex={productsIndex} onSelectOption={(idx) => updateIndex(idx)} />
                </styles.ContainerDotGrid>
                <styles.ContainerMenu>
                    <SidePanel options={productsFiltered} onSelectOption={(idx) => updateIndex(idx)} selectedIndex={productsIndex} />
                </styles.ContainerMenu>
            </styles.ContainerGallery>
            <styles.ContainerHorizontalMenu>
                <HorizontalScroller gallery={productsFiltered} selectedIndex={productsIndex} onSelectOption={(idx) => updateIndex(idx)}/>
            </styles.ContainerHorizontalMenu>
        </styles.ModalContainer>
    );
};


export default Modal;
