import styled from "styled-components";

const GlasssTypesContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  padding-top: 80px;
  padding-bottom: 80px;
  transition: all 0.3s;
  text-align: center;
  background-color: #F1F5F6;

  background-image: ${({image})=> `url(${image})`};
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 992px) {
    padding-top: 20px;
    background-color: #F1F5F6;
    padding-bottom: 20px;
  }
`;

const ContainerMenu = styled.div`
  padding-right: 60px;
  padding-top: 7px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  flex-shrink: 0;
  min-width: 300px;
  @media only screen and (max-width: 992px) {
    display: none;
  }

  @media only screen and (min-width: 1200px) {
    min-width: 398px;
  }
`;

const ContainerMenuMobile = styled.div`
    padding-top: 7px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    @media only screen and (min-width: 992px) {
    display: none;
    }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    width: 330px;
  }
  
  @media only screen and (min-width: 480px) {
    width: 405px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1084px;
  }
`;


const ContainerGallery = styled.div`
  width: 868px;
  overflow-x: scroll;
  white-space: nowrap;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const Title = styled.div`
  color: #1F1F1F;
  font-size: 48px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: bold;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &>svg{
    cursor: pointer;
  }
  
  &>svg>g>path{
    fill: ${({selected})=>selected ? "#3f3f3f":"#909090"};
  }
  &>svg>g{
    ${({selected})=>selected && "fill: white;"}
  }
  &:hover{
    &>svg>g{
      fill: white;
    }
    &>svg>g>path{
      fill: #3f3f3f;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 165px;
    height: 165px;
  }

  @media only screen and (min-width: 600px) {
    width: 265px;
    height: 265px;
  }

  @media only screen and (min-width: 768px) {
    width: 265px;
    height: 265px;
  }
`;

const TexDoorType = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
`;

const SliderMobileContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  min-height: 300px;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export default {
  GlasssTypesContainer,
  ContainerMenu,
  ContainerGallery,
  ContentContainer,
  Title,
  ContainerIcons,
  IconContainer,
  TexDoorType,
  SliderMobileContainer,
  ContainerMenuMobile,
}