import styled from "styled-components";

const ModalContainer = styled.div`
  width: 100vw;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px
  background-color: #FFFFFF;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 90px;
  text-align: right;
  @media only screen and (max-width: 992px) {
    align-items: center;
    padding-top: 0px;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 150px;
  }
`;

const NavbarModal = styled.div`
  width: 100vw;
  height: 81px;
  position: fixed;
  top: 0px;
  left: 0px
  z-index: 200;
  background-color: #EAE8E5;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 992px) {
    background-color: white;
    padding-right: 20px;
  }
`;

const CloseModalButtonContainer = styled.div`
  width: 1100px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 992px) {
    width: auto;
    align-items: center;
    justify-content: flex-start;
    max-width: 90%;
  }
`;

const SearchInput = styled.input`
  height: 37px;
  border-radius: 37px;
  border: 1px solid #707070;
  width: 540px;
  max-width: 100%;
  outline: none;
  @media only screen and (min-width: 992px) {
    margin-left: 280px;
  }
`;

const ContainerListButton = styled.div`
  position: absolute;
  left: 35px;
  cursor: pointer;
  z-index: 200;
  &:hover {
    & >svg>g>line{
      stroke: #2f2f2f
    }
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerHorizontalMenu = styled.div`
  width: 95vw;
  border-left: 5vw solid transparent;
  min-height: 142px;
  background-color: #3F3F3F;
  position: fixed;
  bottom: 0px;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerMenu = styled.div`
  width: 270px;
  padding-top: 100px;
  max-height: 570px;
  overflow:  scroll;
  border-left: 1px solid #707070;
  box-sizing: border-box;
  flex-shrink: 0;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ContainerLeft = styled.div`
  width: 100%
  margin-left: 100px;
  border-bottom: 1px solid #707070;
  height: 80px;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  color: #3E3E3E;
  box-sizing: border-box;
`;

const TitleContainer = styled.div`
  margin-left: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const ContainerRight = styled.div`
  width: 270px
  border-bottom: 1px solid #707070;
  height: 80px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid #707070;
  padding-left: 10px;
  box-sizing: border-box;
`;

const ContainerGallery = styled.div`
  display:  flex;
  flex-direction: row;
  justify-content: stretch;
  max-width: 1100px;
  width: 90%;
  @media only screen and (max-width: 992px) {
    width: 90%;
    max-width: 597px;
  }
`;

const ContainerUpperPart = styled.div`
  display:  flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1100px;
  width: 90%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ContainerMiniGrid = styled.div`
  margin-top: 100px;
  width: 100%;
  max-height: 470px;
  overflow: scroll;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ContainerDotGrid = styled.div`
  width: 100%;
  max-height: 570px;
  overflow:  scroll;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const DoorImage = styled.img`
  width: 199px;
  margin-right: 70px;
  @media only screen and (max-width: 992px) {
    margin-right: 15px;
  }
`;

const ContainerDoorImage = styled.div`
  padding-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 992px) {
    padding-top: 0;
  }
`;

const DoorLine = styled.div`
  position: absolute;
  left: 100px;
  top: 0px;
  width: 50px;
  height: 95px;
  border-left: 1px solid #707070;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
const DoorText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  color: #3F3F3F;
  width: 200px;
  text-align: center;
  margin-top: 10px;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export default {
  ModalContainer,
  ContainerMenu,
  ContainerLeft,
  ContainerRight,
  ContainerGallery,
  ContainerUpperPart,
  GridContainer,
  NavbarModal,
  ContainerListButton,
  CloseModalButtonContainer,
  DoorImage,
  SearchInput,
  ContainerHorizontalMenu,
  ContainerMiniGrid,
  ContainerDotGrid,
  TitleContainer,
  ContainerDoorImage,
  DoorLine,
  DoorText,
}