import React, { useState, useEffect, useContext } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import SidePanel from "components/SidePanel";
import GridRectangles from "components/GridRectangles";
import Grid from "components/Grid";
import ListSvg from "components/ListSvg";
import GridSvg from "components/GridSvg";
import Showcase from "./Showcase";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { ModalContext } from "ModalContext";
import CloseModalButton from './CloseModalButton';
import GalleryExtended from "./GalleryExtended";

const closeMessage = "Wróć do galerii – WNĘTRZA";

const Modal = () => {
    const history = useHistory();
    const [fullModal, setFullModal] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [types, setTypes] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const { interiorId } = useParams();
    const { toggleModal } = useContext(ModalContext);

    const updateTypeIdx = (array, id) => {
        const newIdx = array.findIndex((element) => {
            return element.galleryurl === id;
        })
        setSelectedIdx(newIdx);
    }

    useEffect(() => {
        setGalleryIndex(0);
        (async () => {
            const loadedGallery = await getData(`${interiorId}`);
            setGallery(loadedGallery);
            if (types?.length) {
                updateTypeIdx(types, interiorId);
            }
        })();
    }, [interiorId]);

    useEffect(() => {
        toggleModal();
        (async () => {
            const loadedTypes = await getData('gallery-homes');
            setTypes(loadedTypes);
            updateTypeIdx(loadedTypes, interiorId);
        })();
    }, []);

    const toggleFullModal = (idx) => {
        setGalleryIndex(idx)
        setFullModal(!fullModal);
    }

    const updateIndex = (idx) => {
        setGalleryIndex(0);
        history.push(`/interiors/${types[idx].galleryurl}`)
    };

    const backToLanding = () => {
        toggleModal();
        history.push(`/landing?section=interiors`)
    };

    return (
        <styles.ModalContainer fullModal={fullModal}>
            {!fullModal && <styles.TitlePhoneGroup>WNĘTRZA</styles.TitlePhoneGroup>}
            <styles.NavbarModal fullModal={fullModal}>
                <styles.CloseModalButtonContainer>
                    <CloseModalButton toggleModal={backToLanding} closeMessage={closeMessage} />
                </styles.CloseModalButtonContainer>
                {!fullModal
                    ? <styles.ContainerListButton onClick={backToLanding}><ListSvg /></styles.ContainerListButton>
                    : <styles.ContainerListButton onClick={toggleFullModal}><GridSvg /></styles.ContainerListButton>
                }
            </styles.NavbarModal>
            <styles.ContainerMenu>
                <SidePanel title={"WNĘTRZA"} options={types} onSelectOption={(idx) => updateIndex(idx)} selectedIndex={selectedIdx} />
            </styles.ContainerMenu>
            <styles.ContainerGallery>
                <GalleryExtended gallery={gallery} />
            </styles.ContainerGallery>
            <styles.TitlePhone>{!!types?.length && types[selectedIdx].name}</styles.TitlePhone>
            {!fullModal
                ? <styles.GridContainer><GridRectangles elements={gallery} backgroundColor={"#3F3F3F"} handleElementClick={toggleFullModal} /></styles.GridContainer>
                : <styles.ShowCaseContainer><Showcase gallery={gallery} initialIndex={galleryIndex} /></styles.ShowCaseContainer>
            }
        </styles.ModalContainer>
    );
};  


export default Modal;
