import React, { createRef, useEffect } from "react";
import styles from './styles.js';

const ElementPanel = ({ idx, option, onSelectOption, selected }) => {
    const elementRef = createRef();
    useEffect(() => {
        if (selected) {
            elementRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [selected]);
    return (
        <styles.MenuOption ref={elementRef} selected={selected} onClick={() => onSelectOption(idx)}>{(idx + 1).toString().padStart(3, "0")}&nbsp;{option.name}</styles.MenuOption>
    );
};

export default ElementPanel;
