import React  from 'react';
import styles from './styles';

const Burguer = ({ open, toggle }) => {
    const iconProps = {
        open: [
            { "x1": "33", transform: "translate(-0.358)" },
            { "x1": "32.285", transform: "translate(0 12.5)" },
            { "x1": "33", transform: "translate(-0.358 25)" },
        ],
        close: [
            { "x1": "26.5", "y2": "24.501", transform: "translate(0.5)" },
            { "x1": "0", transform: "translate(13.5 12.501)" },
            { "x1": "27.5", "y1": "24.499", transform: "translate(-0.5 0.501)" },

        ]
    }

    return (
        <styles.ContainerBurguer>
            <styles.Svg onClick={toggle} id="btn_menu_on" data-name="btn_menu_on" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 90 90">
                <styles.Group id="btn_menu_on_g" data-name="btn_menu_on_g" transform="translate(33 32.999)">
                    {iconProps[open ? "close" : "open"]?.map((iconProp, idx) => {
                        return <styles.Line key={idx} id={`line-${idx}`}  {...iconProp} fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth="5" />
                    })}
                </styles.Group>
            </styles.Svg>
        </styles.ContainerBurguer>
    );
};

export default Burguer;
