import React from "react";
import styles from './styles.js';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Handlers = React.forwardRef(({toggleFrames, open, framesOpen, doorTypeIndex}, ref) => {
    
    return (
        <styles.HandlersContainer open={open} ref={ref}>
            <Mobile doorTypeIndex={doorTypeIndex}/>
            <Desktop toggleFrames={toggleFrames} framesOpen={framesOpen}/>
        </styles.HandlersContainer>
    );
});

export default Handlers;
