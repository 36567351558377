import React from "react";
import styles from './styles.js';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';

const Sequencer = ({ galleryLength, updateIndex, selectedIndex }) => {
    return (
        <>
            <styles.ContainerSequencerElement disabled={selectedIndex - 1 < 0} onClick={() => updateIndex(selectedIndex - 1 < 0 ? selectedIndex : selectedIndex - 1)}><ArrowLeft /></styles.ContainerSequencerElement>
            <styles.TextContainer><b>{selectedIndex + 1}</b> / {galleryLength}</styles.TextContainer>
            <styles.ContainerSequencerElement disabled={selectedIndex + 1 >= galleryLength} onClick={() => updateIndex(selectedIndex + 1 >= galleryLength ? selectedIndex : selectedIndex + 1)}><ArrowRight /></styles.ContainerSequencerElement>
        </>
    );
};

export default Sequencer;
