import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles';
import { useHistory } from "react-router";
const SideMenu = ({ open, toggle }) => {
    const history = useHistory();
    return (
        <>  
            <styles.BackPanel open={open} onClick={toggle}/>
            <styles.ContainerPanel open={open}>
                <styles.ContainerMenu >
                    <styles.MenuOption onClick={() => {toggle(); history.push('landing?section=interiors');}}>WNĘTRZA</styles.MenuOption>
                    <styles.MenuOption onClick={() => {toggle(); history.push('landing?section=doors');}}>DRZWI</styles.MenuOption>
                    <styles.MenuOption onClick={() => {toggle(); history.push('landing?section=patterns');}}>WZORY</styles.MenuOption>
                    <styles.MenuOption onClick={() => {toggle(); history.push('landing?section=glasses');}}>SZKŁO</styles.MenuOption>
                    <styles.MenuOptionLink href="/pdf/katalog.pdf" download="katalog.pdf" >POBIERZ KATALOG</styles.MenuOptionLink>
                    <styles.MenuOption onClick={() => {toggle(); history.push('products');}}>PRODUKTY</styles.MenuOption>
                    <styles.MenuOptionLink  href="/pdf/cennik.pdf" download="cennik.pdf">CENNIK</styles.MenuOptionLink>
                    <styles.MenuOption  onClick={() => {toggle(); history.push('landing?section=contact');}}>KONTAKT</styles.MenuOption>
                </styles.ContainerMenu>
            </styles.ContainerPanel>
        </>

    );
};

export default withRouter(SideMenu);
