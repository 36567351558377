import styled from "styled-components";

const Image = styled.img`
  height: 100%;
  display: inline-block;
`;

const ContainerSpinner = styled.div`
  height: 100%;
  display: inline-block;
  width: 500px;
  &>div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default {
  Image,
  ContainerSpinner,
}