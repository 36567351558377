import React from 'react';
import { withRouter } from 'react-router';
import logo from 'media/logo/logo@2x.png';
import styles from './styles';
import Burguer from '../Burguer/index';

const NavElements = ({open, toggle}) => {
    return (
        <>
            <styles.LogoLink to="/">
                <styles.Logo src={logo} alt="Szklane Drzwi" />
            </styles.LogoLink>
            <styles.Nav >
                <Burguer toggle={toggle} open={open} />
                <styles.Menu>
                    <styles.Link to="/products/salonimages">  PRODUKTY </styles.Link>
                    <styles.ALink href="/pdf/katalog.pdf" download="katalog.pdf"> KATALOG  </styles.ALink>
                    <styles.ALink href="/pdf/cennik.pdf" download="cennik.pdf"> CENNIK </styles.ALink>
                    <styles.Link to="/landing?section=contact"> KONTAKT </styles.Link>
                </styles.Menu>
            </styles.Nav>
        </>

    );
};

export default withRouter(NavElements);
