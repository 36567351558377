import styled from "styled-components";


const Container = styled.div`
  width: 100%;
`;

const ContainerImages = styled.div`
  width: 100%;
  height: 280px;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  left: ${({idx, selectedIdx})=> ((idx - selectedIdx) * 100)}%;
  transition: all 0.7s;
`;


const ContainerSequencer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContainerSequencerElement = styled.div`
width: 100px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
& > svg > path {
  ${({disabled})=> disabled ? 'fill: #707070;' : 'fill: #eeeeee;'}
}
  &: hover{
    & > svg > path {
      fill: #707070;
      transition: all 0.2s;
    }
  }
`;

const TextContainer = styled.div`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
`;

const MiddleDot = styled.div`
  width:5px;
  height:5px;
  border-radius:5px;
  border: 1px solid #707070;
  ${({selected})=>selected && 'background-color: #707070;'}
  transition: all 0.7s;
`;

export default {
  Container,
  ContainerImages,
  Image,
  ContainerSequencer,
  MiddleDot,
  ContainerSequencerElement,
  TextContainer,
}