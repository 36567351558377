import React from 'react';
import * as styledComponents from './styles';

const ArrowToTop = () => {
    return (
        <styledComponents.SVG id="GO_TOP" data-name="GO TOP" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
            <g id="Ellipse_1" data-name="Ellipse 1" fill="rgba(221,221,221,0.53)" stroke="#fff" stroke-width="2">
                <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
                <circle cx="20.5" cy="20.5" r="19.5" fill="none" />
            </g>
            <g id="Group_188" data-name="Group 188" transform="translate(-1178 -635)">
                <line id="Line_2" data-name="Line 2" y2="23" transform="translate(1198.5 643.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
                <line id="Line_3" data-name="Line 3" x1="7" y2="7" transform="translate(1191.5 643.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
                <line id="Line_4" data-name="Line 4" x2="7" y2="7" transform="translate(1198.5 643.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
            </g>
        </styledComponents.SVG>
    );
};

export default ArrowToTop;
