import React, { useState, useEffect } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import GalleryExtended from "components/GalleryExtended";
import SidePanel from "components/SidePanel/index.js";
import Slider from "components/Slider";
import ArrowDownOpen from 'components/ArrowDownOpen';
import DoorsSVG from './DoorTypes';
import VisibilitySensor from 'react-visibility-sensor';
import { useHistory } from "react-router";

const Doors = React.forwardRef(({ toggleHandlers, handlersOpen, updateDoorTypeParent }, ref) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [doorTypes, setDoorTypes] = useState([]);
    const [gallery, setGallery] = useState([]);
    const history = useHistory();
    useEffect(() => {
        (async () => {
            if (doorTypes?.length) {
                const loadedGallery = await getData(`${doorTypes[selectedIndex].galleryurl}`);
                if (loadedGallery) {
                    setGallery(loadedGallery);
                }
            }
        })();
    }, [doorTypes, selectedIndex]);

    useEffect(() => {
        (async () => {
            const loadedDoorTypes = await getData('door-types');
            setDoorTypes(loadedDoorTypes);
        })();
    }, []);

    const changeVisibility = (isVisible) => {
        if (isVisible) {
            history.push('landing?section=doors&type=scrolled')
        }
    }

    const updateDoorType = (idx) => {
        setSelectedIndex(idx);
        updateDoorTypeParent(idx);
    }

    return (
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
            <styles.DoorsContainer open={handlersOpen} ref={ref}>
                <styles.ContentContainer>
                    <styles.Title>DRZWI</styles.Title>
                    <styles.ContainerIcons>
                        {doorTypes?.map((doorType, idx) => {
                            return <styles.IconContainer onClick={() => updateDoorType(idx)} selected={idx === selectedIndex}><DoorsSVG svgname={doorType.svgname} /><styles.TexDoorType>{doorType.name}</styles.TexDoorType></styles.IconContainer>
                        })}
                    </styles.ContainerIcons>
                    <styles.SliderContainer>
                        <Slider gallery={gallery} />
                    </styles.SliderContainer>
                    <styles.ContainerMenu>
                        <SidePanel title={"DRZWI"} options={doorTypes} onSelectOption={(idx) => setSelectedIndex(idx)} selectedIndex={selectedIndex} />
                        <styles.ContainerArrowDown>
                            <ArrowDownOpen open={handlersOpen} toggle={toggleHandlers} messageOpen={!handlersOpen ? 'Rozwiń okucia' : 'Zwiń okucia '} />
                            <styles.NextSectionDivider open={handlersOpen} />
                        </styles.ContainerArrowDown>
                    </styles.ContainerMenu>
                    <styles.ContainerGallery>
                        <GalleryExtended gallery={gallery} />
                    </styles.ContainerGallery>
                </styles.ContentContainer>
            </styles.DoorsContainer>
        </VisibilitySensor>
    );
});

export default Doors;
