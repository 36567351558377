import React, { useState, useEffect } from "react";
import styles from './styles.js';
import Background from 'media/images/Background.jpg'
import imageLoader from 'helpers/imageLoader';
import { useHistory } from "react-router";
import VisibilitySensor from 'react-visibility-sensor';

const Landing = React.forwardRef(({ }, ref) => {
    const history = useHistory();
    const [BILoaded, setBILoaded] = useState();
    const [SLLoaded, setSLLoaded] = useState();
    const [DLLoaded, setDLLoaded] = useState();
    const [LogoLoaded, setLogoLoaded] = useState();
    const [animation, setAnimation] = useState(true);
    const [animationOnGoing, setAnimationOnGoing] = useState(false);
    const changeVisibility = (isVisible) => {
        if (isVisible) {
            history.push('landing?section=start&type=scrolled')
        }
    }

    useEffect(() => {
        (async () => {
            const BILoadedResponse = await imageLoader('/media/Background@2x.png');
            const SLLoadedResponse = await imageLoader('/media/SingleLeaf@2x.png');
            const DLLoadedResponse = await imageLoader('/media/MultiLeaf@2x.png');
            const LogoResponse = await imageLoader('/media/logo@2x.png');
            setBILoaded(BILoadedResponse);
            setSLLoaded(SLLoadedResponse);
            setDLLoaded(DLLoadedResponse);
            setLogoLoaded(LogoResponse);
        })();
    }, []);

    const handleClickContainer = (e) => {
        if(BILoaded && SLLoaded && LogoLoaded && DLLoaded && !animationOnGoing){
            setAnimation(false);
            setAnimationOnGoing(true);
            setTimeout(()=>{
                setAnimation(true);
                setTimeout(()=>{
                    setAnimationOnGoing(false);
                }, 1000)
            }, 2000)
        }
    }

    return (
        <VisibilitySensor partialVisibility onChange={changeVisibility}>
            <styles.HomeContainer ref={ref}  onClick={handleClickContainer}>
                <styles.Background src={BILoaded || Background} loaded={BILoaded && SLLoaded && LogoLoaded && DLLoaded && animation} />
                <styles.SingleLeaf src={SLLoaded} loaded={BILoaded && SLLoaded && LogoLoaded && DLLoaded && animation} />
                <styles.ContainerHeroLogo>
                    <styles.ContainerImage>
                        <styles.ImageLogo src={LogoLoaded} loaded={BILoaded && SLLoaded && LogoLoaded && DLLoaded && animation} />
                    </styles.ContainerImage>
                </styles.ContainerHeroLogo>
                <styles.MultiLeaf src={DLLoaded} loaded={BILoaded && SLLoaded && LogoLoaded && DLLoaded && animation} />
                <styles.ContainerGlass>
                    <styles.Glass />
                    <styles.ContainerMenu>
                        <styles.MenuOption onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('landing?section=interiors'); }}>WNĘTRZA</styles.MenuOption>
                        <styles.MenuOption onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('landing?section=doors') }}>DRZWI</styles.MenuOption>
                        <styles.MenuOption onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('landing?section=patterns') }}>WZORY</styles.MenuOption>
                        <styles.MenuOption onClick={(e) => { e.preventDefault(); e.stopPropagation(); history.push('landing?section=glasses') }}>SZKŁO</styles.MenuOption>
                    </styles.ContainerMenu>
                </styles.ContainerGlass>
            </styles.HomeContainer>
        </VisibilitySensor>
    );
});

export default Landing;
