import styled from "styled-components";

const ContainerFooter = styled.div`
  width: 100%;
  position: relative;
  min-height: 703px;
  background-color: #273725;
  @media only screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const ContainerMoto = styled.div`
  width: 350px;
  max-width: 80%;
  margin-bottom: 40px;
  padding-top: 77px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 992px) {
    width: 100%;
    min-height: 200px;
    margin-left: 20px;
    margin-bottom: 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media screen and (min-width: 1350px) {
    width: 100%;
    max-width: 100%;
    margin-left: 140px;
  }

`;

const Button = styled.div`
  width: 350px;
  max-width: 100%;
  height: 60px;
  border: 3px solid white;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media only screen and (max-width: 992px) {
    margin-bottom: 50px;
  }
  cursor: pointer;
`;

const Text = styled.div`
  width: 350px;
  max-width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  & > a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
`;

const DataMobile = styled.div`
  width: 350px;
  max-width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  display: inline;
  align-items: flex-start;
  flex-direction: column;
  & > a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  justify-content: flex-start;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const Line = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
  width: 100%;
  height:2px;
  background-color: #FFFFFF;
  position: absolute;
  top: 77px;
`;

const ImageFooter = styled.div`
  width: 100%;
  height: 630px;
  background-image: ${({imageMobile})=> `url(${imageMobile})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-width: 992px) {
    background-image: ${({image})=> `url(${image})`};
    background-position: right center;
    padding-right: 185px;
    position: absolute;
    width: 50%;
    max-width: 1100px;
    height: 100%;
    right: 0px;
    top: 0px;
  }
`;

const TextOverImage = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
  & > a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  position: absolute;
  top: 114px;
  left: 40px;
  display: inline;
`;

const LineImage = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
  width: 100%;
  height: 2px;
  background-color: #FFFFFF;
  margin-top: 77px;
`;

export default {
  ContainerFooter,
  ImageFooter,
  Line,
  ContainerMoto,
  Button,
  Text,
  TextOverImage,
  LineImage,
  DataMobile,
}
