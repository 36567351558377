import styled from "styled-components";

const ModalContainer = styled.div`
  width: 100vw;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px
  background-color: #FFFFFF;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 90px;
  text-align: right;
  @media only screen and (max-width: 992px) {
    align-items: center;
    padding-top: 0px;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 150px;
  }
`;

const NavbarModal = styled.div`
  width: 100vw;
  height: 81px;
  position: fixed;
  top: 0px;
  left: 0px
  z-index: 200;
  background-color: #FAF9F7;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    background-image: ${({img, detailOpen})=> detailOpen && `url(${img})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media only screen and (min-width: 992px) {
    background-color: #FAF9F7;
    padding-right: 20px;
  }
`;

const CloseModalButtonContainer = styled.div`
  width: 1100px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 992px) {
    width: auto;
    align-items: center;
    justify-content: flex-start;
    max-width: 90%;
  }
`;

const SearchInput = styled.input`
  height: 37px;
  border-radius: 37px;
  border: 1px solid #707070;
  width: 540px;
  max-width: 100%;
  outline: none;
  @media only screen and (min-width: 992px) {
    margin-left: 280px;
  }
`;

const ContainerListButton = styled.div`
  position: absolute;
  left: 35px;
  cursor: pointer;
  z-index: 200;
  & >svg>g>line{
    stroke: #FFFFFF;
  }
  & >svg>g>path{
    stroke: #FFFFFF;
  }
  & >svg>g>g>g{
    stroke: #FFFFFF;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const ContainerHorizontalMenu = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const HorizontalMenuOption = styled.div`
  width: 100%;
  text-align: center
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  color: #909090;
  background-color: rgba(198, 197, 196, 0.24);
  line-height: 39px;
  cursor: pointer;
`;

const ContainerMenu = styled.div`
  width: 270px;
  padding-top: 100px;
  max-height: 570px;
  overflow:  scroll;
  border-left: 1px solid #707070;
  box-sizing: border-box;
  flex-shrink: 0;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const TitleHorizontalMenu = styled.div`
  color: #3E3E3E;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  width: 100%;
  text-align: center;
  font-stretch: condensed;
  border-bottom: 1px solid #909090;
`;

const ContainerLeft = styled.div`
  width: 100%
  margin-left: 250px;
  border-bottom: 1px solid #707070;
  height: 80px;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  color: #3E3E3E;
  box-sizing: border-box;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


const ContainerRight = styled.div`
  width: 270px
  border-bottom: 1px solid #707070;
  height: 80px;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  border-left: 1px solid #707070;
  padding-left: 10px;
  box-sizing: border-box;
`;

const ContainerGallery = styled.div`
  display:  flex;
  flex-direction: row;
  justify-content: stretch;
  max-width: 1100px;
  width: 90%;
  @media only screen and (max-width: 992px) {
    width: 90%;
    max-width: 597px;
    flex-direction: column;
  }
`;

const ContainerUpperPart = styled.div`
  display:  flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1100px;
  width: 90%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const ContainerAddressesData = styled.div`
  margin-top: 100px;
  width: 100%;
  max-height: 470px;
  overflow: scroll;
  @media only screen and (max-width: 992px) {
    margin-top: 20px;
    max-height: 100wh;
  }
`;

const DoorImage = styled.img`
  width: 100%;
  @media only screen and (max-width: 992px) {
    margin-right: 15px;
  }
`;

const LocationDot = styled.div`
  border-radius: 14px;
  border: 0.5px solid #909090;
  box-sizing: border-box;
  ${({selected})=> selected && 'background-color: #2E2E2E'}
  width: ${({selected})=> selected ? '15px': '7px'};
  height: ${({selected})=> selected ? '15px': '7px'};
`;

const LocationDotContainer = styled.div`
  width: ${({selected})=> selected ? '25px': '25px'};
  height: ${({selected})=> selected ? '25px': '25px'};
  border-radius: 14px;
  box-sizing: border-box;
  position: absolute;
  left: ${({x})=> x }%;
  top: ${({y})=> y }%;
  margin-left: -12px;
  margin-top: -12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerDoorImage = styled.div`
  padding-top: 100px;
  margin-right: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 992px) {
    padding-top: 0;
    margin-right: 0;
  }
`;

const DoorLine = styled.div`
  position: absolute;
  left: 250px;
  top: 0px;
  width: 50px;
  height: 95px;
  border-left: 1px solid #707070;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const AddressContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #2E2E2E;
  padding-right: 20px;
  text-align: left;
  margin-bottom: 20px;
`;

const AlternativeAddress = styled.div`
  margin-top: 20px;
  font-weight: 400;
`;

const ContainerMap = styled.div`
  position: relative;
  width: 500px;
  max-width: 100%;
`;

const ContainerOtherAddresses = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  border-top: 1px solid #909090;
  color: #909090;
  text-align: left;
`;

const DoorText = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  color: #3F3F3F;
  width: 200px;
  text-align: center;
  margin-top: 10px;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export default {
  ModalContainer,
  ContainerMenu,
  ContainerLeft,
  ContainerRight,
  ContainerGallery,
  ContainerUpperPart,
  GridContainer,
  NavbarModal,
  ContainerListButton,
  CloseModalButtonContainer,
  DoorImage,
  SearchInput,
  ContainerHorizontalMenu,
  ContainerAddressesData,
  TitleContainer,
  ContainerDoorImage,
  DoorLine,
  DoorText,
  AddressContainer,
  ContainerOtherAddresses,
  AlternativeAddress,
  ContainerMap,
  LocationDot,
  HorizontalMenuOption,
  TitleHorizontalMenu,
  LocationDotContainer,
}