import React, {useState} from 'react';
import ArrowToTop from 'components/ArrowToTop';
import './ButtonTop.scss';

const text = {
  back : {
    en: 'back',
    pl: 'powrót'
  }
}

const ButtonTop =  (props) => {

  let [shown,toggleShown] = useState(false)
  window.addEventListener('scroll', function(e) {
    console.log(window.scrollY);
    let last_known_scroll_position = window.scrollY;
    if(last_known_scroll_position > 300){
      toggleShown(true)
    }else{
      toggleShown(false)
    }
  });

  return (
    <React.Fragment>
      Hi
      {shown &&
        <div onClick={props.handleClick} id="arrow-up">
          <ArrowToTop />
        </div>
      }
    </React.Fragment>
  );
}

export default ButtonTop;
