import React, { useState } from "react";
import styles from './styles.js';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import CMSAPI from "cmsapi";

const Showcase = ({ gallery, initialIndex}) => {

    const [selectedIndex, setSelectedIndex] = useState(initialIndex);

    return (
        <styles.Container>
            <styles.ContainerImages>
                {gallery?.map((element, idx) => <styles.Image img={`${CMSAPI}${element.image.url}`} idx={idx} selectedIdx={selectedIndex} />)}
            </styles.ContainerImages>
            <styles.ContainerSequencer>
                <styles.ContainerSequencerElement disabled={selectedIndex - 1 < 0 } onClick={() => setSelectedIndex(selectedIndex - 1 < 0 ? selectedIndex : selectedIndex - 1)}><ArrowLeft /></styles.ContainerSequencerElement>
                <styles.TextContainer><b>{selectedIndex+1}</b> / {gallery?.length}</styles.TextContainer>
                <styles.ContainerSequencerElement disabled={selectedIndex + 1 >= gallery?.length} onClick={() => setSelectedIndex(selectedIndex + 1 >= gallery?.length ? selectedIndex : selectedIndex + 1)}><ArrowRight /></styles.ContainerSequencerElement>
            </styles.ContainerSequencer>
        </styles.Container>
    );
};

export default Showcase;
