import React from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import Square from './Square';

const Grid = ({hiddenPhone, hiddenDesktop, backgroundColor,  elements, handleElementClick}) => {
    return (
        <styles.GridContainer hiddenPhone={hiddenPhone} hiddenDesktop={hiddenDesktop}>
        {elements?.map((element, idx) => {
            return <Square element={element} backgroundColor={backgroundColor} handleElementClick={handleElementClick} idx={idx}/>
        })}
    </styles.GridContainer>
    );
};

export default Grid;
