let endpoint = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case 'development':
    endpoint = process.env.REACT_APP_SZKLANE_DRZWI_DEV
    break;
  case 'staging':
    endpoint = process.env.REACT_APP_SZKLANE_DRZWI_TEST
    break;
  case 'production':
    endpoint = process.env.REACT_APP_SZKLANE_DRZWI_PROD
    break;
  default:
    endpoint = process.env.REACT_APP_SZKLANE_DRZWI_DEV
}

export default endpoint;
