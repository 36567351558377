import React from "react";

const LeftImg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="75.2" height="93.985" viewBox="0 0 75.2 93.985">
            <defs>
                <filter id="go_left" x="0" y="0" width="75.2" height="93.985" filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="7.5" result="blur" />
                    <feFlood flood-opacity="0.302" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#go_left)">
                <path id="go_left-2" data-name="go left" d="M658.535,410.993,639.474,387l19.061-23.993h-9.463L630.01,387l19.062,23.992Z" transform="translate(-606.87 -340.01)" fill="none" stroke="rgba(112,112,112,0)" stroke-width="1" />
            </g>
        </svg>
    );
};

export default LeftImg;
