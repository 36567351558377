import styled from "styled-components";


const ContainerMenu = styled.div`
  width: 100%;
  display:  flex;
  flex-direction: column;
  align-items: flex-end;
`;


const Title = styled.div`
  color: #3F3F3F;
  font-size: 48px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 800;
  height: 60px;
  margin-bottom: 45px;
  letter-spacing: 1px;
`;

const MenuOption = styled.div`
  position: relative;
  text-align: right;
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 36px;
  line-height: 60px;
  color: #3F3F3F;
  text-transform: uppercase;
  border-bottom: 1px solid ${({selected})=> selected ? "#3F3F3F" : "transparent"};
  border-top: 1px solid ${({selected})=> selected ? "#3F3F3F" : "transparent"};
  cursor: pointer;
  box-sizing: border-box;
  max-width: 300px;
  &:hover{
    color: #3F3F3F;
    opacity: 0.4;
  }
`;

export default {
  ContainerMenu,
  Title,
  MenuOption,
}