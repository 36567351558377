import React, { useEffect, useState } from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import imageLoader from 'helpers/imageLoader';
import { FlagSpinner  } from "react-spinners-kit";

const Square = ({ idx, element, backgroundColor, handleElementClick }) => {
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        (async () => {
            const imageLoaded = await imageLoader(`${CMSAPI}${element.image.url}`);
            setImageUrl(imageLoaded);
        })();
    }, []);

    return (
        <styles.Square image={imageUrl} backgroundColor={backgroundColor} onClick={() => handleElementClick && handleElementClick(idx)}>
            {
                imageUrl
                    ? <styles.SquareContent>{element.name}</styles.SquareContent>
                    : <FlagSpinner  size={30} color="#686769"/>
            }
        </styles.Square>
    );
};

export default Square;
