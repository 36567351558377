import styled from "styled-components";


const Container = styled.div`
  width: 90%;
  max-width: 597px;
  position: relative;
`;

const Image = styled.img`
  width: 55px;
  height: 118px;
  position: absolute;
  top: 12px;
  left: ${({idx, selectedIndex})=> (75 + (idx - selectedIndex) * 75)}px;
  transition: all 0.7s;
  flex-shrink: 0;
`;


export default {
  Container,
  Image,
}