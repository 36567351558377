import React from "react";
import styles from './styles.js';
import Image from "./Image";

const Slider = ({ gallery }) => {

    return (
        <styles.SliderContainer>
            {gallery?.map((element, idx) => <Image element={element} idx={idx} />)}
        </styles.SliderContainer>
    );
};

export default Slider;
