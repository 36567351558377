import styled from "styled-components";


const ContainerMenu = styled.div`
  width: 100%;
  display:  flex;
  flex-direction: column;
  align-items: flex-end;
`;


const Title = styled.div`
  color: #1F1F1F;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 500;
  height: 60px;
  margin-bottom: 60px;
`;

const MenuOption = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  padding-left: 10px;
  color: ${({selected})=> selected ? "#FFFFFF":"#909090"};
  line-height: 24px;
  background-color: ${({selected})=> selected ? "#707070" : "rgba(198, 197, 196, 0.24)"};
  cursor: pointer;
  box-sizing: border-box;
  &:hover{
    color: #C6C5C4;
  }
`;

export default {
  ContainerMenu,
  Title,
  MenuOption,
}