import React, { useState, useEffect, useContext } from "react";
import styles from './styles.js';
import { getData } from 'helpers/getGallery';
import SidePanel from "./SidePanel";
import { useHistory } from "react-router";
import { ModalContext } from "ModalContext";
import CloseModalButton from './CloseModalButton';
import MapImage from 'media/images/map.jpg';
import parse from 'html-react-parser';
import DetectMobile from 'helpers/DetectMobile.js';
import ListSvg from "components/ListSvg";
import CMSAPI from "cmsapi";

const Modal = () => {
    const history = useHistory();
    const [locationsIndex, setLocationIndex] = useState(0);
    const [locations, setLocations] = useState([]);
    const [locationsFiltered, setFilteredLocations] = useState([]);
    const { toggleModal } = useContext(ModalContext);
    const [detailOpen, setDetailOpen] = useState(false);

    useEffect(() => {
        toggleModal();
        (async () => {
            const loadedLocations = await getData('locations');
            setLocations(loadedLocations);
            setFilteredLocations(loadedLocations);
        })();
    }, []);

    const updateIndex = (idx) => {
        setLocationIndex(idx);
    };

    const backToLanding = () => {
        toggleModal();
        history.push(`/landing`)
    };

    const handleSearch = (e) => {
        setLocationIndex(0);
        const newLocationsFiltered = locations.filter((location) => {
            return location.name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setFilteredLocations(newLocationsFiltered)
    }

    return (
        <styles.ModalContainer>
            <styles.NavbarModal detailOpen={detailOpen} img={locationsFiltered?.length && `${CMSAPI}${locationsFiltered[locationsIndex]?.image?.url}`}>
                <styles.CloseModalButtonContainer>
                    {
                        detailOpen ? 
                        <styles.ContainerListButton onClick={()=>{setDetailOpen(false)}}><ListSvg /></styles.ContainerListButton>
                        :<>
                        <styles.SearchInput onChange={handleSearch} />
                        <CloseModalButton toggleModal={backToLanding} />
                        </>
                    }
                </styles.CloseModalButtonContainer>
            </styles.NavbarModal>
            <styles.ContainerUpperPart>
                <styles.ContainerLeft>
                    <styles.TitleContainer>
                        Przedstawiciel handlowy
                    </styles.TitleContainer>
                </styles.ContainerLeft>
                <styles.ContainerRight>
                    Miasto – wojwództwo
                </styles.ContainerRight>
            </styles.ContainerUpperPart>
            <styles.ContainerGallery>
                <styles.ContainerDoorImage>
                    <styles.DoorLine />
                    <styles.ContainerMap>
                        <styles.DoorImage src={MapImage} />
                        {locationsFiltered?.map((location, idx) => {
                            return <styles.LocationDotContainer  x={location.x} y={location.y} onClick={()=>updateIndex(idx)}><styles.LocationDot selected={idx === locationsIndex} /></styles.LocationDotContainer>
                        })}
                    </styles.ContainerMap>
                </styles.ContainerDoorImage>
                {!!locationsFiltered?.length && (detailOpen || !DetectMobile())
                    && <styles.ContainerAddressesData>
                        <styles.AddressContainer>
                            {!!locationsFiltered[locationsIndex].AddressA && parse(locationsFiltered[locationsIndex].AddressA)}
                        </styles.AddressContainer>
                        <styles.AddressContainer>
                            {!!locationsFiltered[locationsIndex].AddressB && parse(locationsFiltered[locationsIndex].AddressB)}
                        </styles.AddressContainer>
                        {
                            !!locationsFiltered[locationsIndex].AlternativeAddressA &&
                            <styles.ContainerOtherAddresses>
                                inne sąsiednie adresy <br />
                            Przedstawicieli handlowych<br /><br />
                                <styles.AlternativeAddress>
                                    {parse(locationsFiltered[locationsIndex].AlternativeAddressA)}
                                </styles.AlternativeAddress>
                                <styles.AlternativeAddress>
                                    {!!locationsFiltered[locationsIndex].AlternativeAddressB && parse(locationsFiltered[locationsIndex].AlternativeAddressB)}
                                </styles.AlternativeAddress>
                            </styles.ContainerOtherAddresses>
                        }
                    </styles.ContainerAddressesData>
                }
                <styles.ContainerMenu>
                    <SidePanel options={locationsFiltered} onSelectOption={(idx) => updateIndex(idx)} selectedIndex={locationsIndex} />
                </styles.ContainerMenu>
            </styles.ContainerGallery>
            {
                !detailOpen &&
                <styles.ContainerHorizontalMenu>
                    <styles.TitleHorizontalMenu>Wybierz miasto</styles.TitleHorizontalMenu>
                    {locationsFiltered?.map((location, idx) => {
                        return <styles.HorizontalMenuOption onClick={() => { updateIndex(idx); setDetailOpen(true); }}>{location.name}</styles.HorizontalMenuOption>
                    })}
                </styles.ContainerHorizontalMenu>
            }
        </styles.ModalContainer>
    );
};


export default Modal;
