import React, { useState } from 'react';
export const ModalContext = React.createContext();
export const ModalProvider = ({ children }) => {
  const [initialIndex, setInitialIndex] = useState(0);
  const [modalOpen, setModalState] = useState(false);
  const [closeMessage,  setCloseMessage] = useState("");
  const toggleModal = ()=>{
    setModalState(!modalOpen);
  }

  return (
    <ModalContext.Provider
      value={{
        modalShown: modalOpen,
        toggleModal: toggleModal,
        closeMessage: closeMessage,
        setCloseMessage: setCloseMessage,
        initialIndex: initialIndex,
        setInitialIndex: setInitialIndex,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
