import styled from "styled-components";

const Line = styled.line`
  transition: all 0.7s;
`;

const Svg = styled.svg`
`;

const Group = styled.g`
`;

const ContainerBurguer = styled.div`
  z-index: 101;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(46, 46, 46, 0.35);
  @media screen and (min-width: 992px) {
    display: none;
  }
`

export default {
  Line,
  Svg,
  Group,
  ContainerBurguer,
}
