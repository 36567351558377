import React from 'react';

const ListButton = ({ open, toggle }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="lista_ikonka" data-name="lista ikonka" transform="translate(0)">
                <g id="ikoGaleria" transform="translate(0)">
                    <g id="Rectangle_147156" data-name="Rectangle 147156" fill="none" stroke="#3f3f3f" stroke-width="2">
                        <rect width="11" height="11" stroke="none" />
                        <rect x="1" y="1" width="9" height="9" fill="none" />
                    </g>
                    <g id="Rectangle_147156-2" data-name="Rectangle 147156" transform="translate(0 14.5)" fill="none" stroke="#3f3f3f" stroke-width="2">
                        <rect width="11" height="11" stroke="none" />
                        <rect x="1" y="1" width="9" height="9" fill="none" />
                    </g>
                    <g id="Rectangle_147156-3" data-name="Rectangle 147156" transform="translate(0 29)" fill="none" stroke="#3f3f3f" stroke-width="2">
                        <rect width="11" height="11" stroke="none" />
                        <rect x="1" y="1" width="9" height="9" fill="none" />
                    </g>
                </g>
                <line id="Line_20" data-name="Line 20" x2="24.5" transform="translate(15.5 1.5)" fill="none" stroke="#3f3f3f" stroke-width="2" />
                <line id="Line_21" data-name="Line 21" x2="10" transform="translate(15 5.5)" fill="none" stroke="#3f3f3f" stroke-width="2" />
                <line id="Line_22" data-name="Line 22" x2="10" transform="translate(15 20)" fill="none" stroke="#3f3f3f" stroke-width="2" />
                <line id="Line_23" data-name="Line 23" x2="10" transform="translate(15 33.5)" fill="none" stroke="#3f3f3f" stroke-width="2" />
                <path id="Path_2880" data-name="Path 2880" d="M0,0H24.5" transform="translate(15.5 15.676)" fill="none" stroke="#3f3f3f" stroke-width="2" />
                <line id="Line_25" data-name="Line 25" x2="24.5" transform="translate(15.5 29.73)" fill="none" stroke="#3f3f3f" stroke-width="2" />
            </g>
        </svg>
    );
};

export default ListButton;
