import React, { useState, useEffect, useContext } from "react";
import {ModalContext}  from 'ModalContext';
import styles from './styles.js';
import getGallery, {getData} from 'helpers/getGallery';
import Gallery from "components/Gallery";
import SidePanel from "components/SidePanel";
import Grid from "components/Grid";
import ListSvg from "components/ListSvg";
import GridSvg from "components/GridSvg";
import Showcase from "./Showcase";

const Modal = () => {
    const { initialIndex, toggleModal } = useContext(ModalContext);
    const [fullModal, setFullModal ] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(initialIndex);
    const [types, setTypes] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [galleryIndex, setGalleryIndex] = useState(0);

    useEffect(()=>{
        (async () => {
            setGallery([]);
            if(types?.length > 0){
                const loadedGallery = await getData(`${types[selectedIndex].galleryurl}`);
                setGallery(loadedGallery);
            }
        })();
    }, [selectedIndex, types]);

    useEffect(() => {
        (async () => {
            const loadedTypes = await getGallery();
            setTypes(loadedTypes);
        })();
    }, []);

    const toggleFullModal = (idx)=> {
        setGalleryIndex(idx)
        setFullModal(!fullModal);
    }

    return (
        <styles.ModalContainer fullModal={fullModal}>
            <styles.NavbarModal fullModal={fullModal}>
                {!fullModal 
                    ? <styles.ContainerListButton onClick={toggleModal}><ListSvg /></styles.ContainerListButton>
                    : <styles.ContainerListButton onClick={toggleFullModal}><GridSvg /></styles.ContainerListButton>
                }
            </styles.NavbarModal>
            <styles.ContainerMenu>
                <SidePanel title={"WNĘTRZA"}  options={types} onSelectOption={(idx)=>setSelectedIndex(idx)} selectedIndex={selectedIndex}/>
            </styles.ContainerMenu>
            <styles.ContainerGallery>
                <Gallery gallery={gallery}/>
            </styles.ContainerGallery>
            <styles.TitlePhone>{types?.length && types[selectedIndex].name}</styles.TitlePhone>
            {!fullModal
                ? <styles.GridContainer><Grid elements={gallery} backgroundColor={"#3F3F3F"} handleElementClick={toggleFullModal}/></styles.GridContainer>
                : <styles.ShowCaseContainer><Showcase gallery={gallery} initialIndex={galleryIndex}/></styles.ShowCaseContainer>
            }
        </styles.ModalContainer>
    );
};


export default Modal;
