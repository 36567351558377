import React, { useEffect, useState } from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import imageLoader from 'helpers/imageLoader';
import { BarsSpinner   } from "react-spinners-kit";

const Image = ({ idx, element}) => {
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        (async () => {
            setImageUrl('');
            const imageLoaded = await imageLoader(`${CMSAPI}${element.image.url}`);
            setImageUrl(imageLoaded);
        })();
    }, [element.image.url]);

    return (
        !!imageUrl
        ? (<styles.Image src={imageUrl} idx={idx} />)
        : (<styles.ContainerSpinner><div><BarsSpinner  size={30} color="#686769"/></div></styles.ContainerSpinner>)
    );
};

export default Image;
