import React from "react";
import styles from './styles.js';
import Image from './Image';

const MiniGrid = ({ gallery, selectedIndex, onSelectOption }) => {
    return (
        <styles.Container>
            {gallery?.map((element, idx) => <Image onSelectOption={onSelectOption} element={element} idx={idx} selected={selectedIndex === idx} />)}
        </styles.Container>
    );
};

export default MiniGrid;
