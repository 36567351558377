import styled from "styled-components";

const HandlersContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  text-align: right;
  position: relative;
  @media only screen and (min-width: 992px) {
    max-height: ${({open})=>open ? '60px;':'0px'};
    max-height: ${({open})=>open ? '200vh':'0px'};
    padding-top: ${({open})=>open ? '60px;':'0px'};
  }
  padding-bottom: 0px;
  transition: all 0.4s;
  overflow-y: hidden;
  @media only screen and (max-width: 992px) {
    background-color: #EAE8E5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 20px;
  }

`;

export default {
  HandlersContainer,
}