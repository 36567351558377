import styled from "styled-components";

const ContainerMenu = styled.div`
  padding-right: 60px;
  padding-top: 7px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  flex-shrink: 0;
  @media only screen and (max-width: 992px) {
    display: none;
    visibility: none;
  }
  @media only screen and (min-width: 992px) {
    width: 300px;
  }

  @media only screen and (min-width: 1200px) {
    width: 398px;
  }
`;

const ContainerArrowDown = styled.div`
  margin-top: 20px;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
`;


const ContainerTypow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
`;

const Typ = styled.div`
  color: ${({ selected }) => selected ? '#3F3F3F' : '#C6C5C4'};
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  cursor: pointer;
`;

const MiddleLine = styled.div`
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  margin: 0px 10px;
  color: #707070;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1084px;
  }
`;


const ContainerGallery = styled.div`
  width: 200vw;
  @media only screen and (max-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const TextTitle = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  width: 100%;
  border-bottom: 1px solid #11110E;
  text-align: left;
  line-height: 40px;
  @media only screen and (min-width: 992px) {
    width: 505px;
  }

  @media only screen and (min-width: 1200px) {
    width: 686px;
  }
`;

const Text = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
  
  @media only screen and (min-width: 992px) {
    width: 505px;
  }

  @media only screen and (min-width: 1200px) {
    width: 686px;
  }
`;

const NextSectionDivider = styled.div`
  width: ${({open}) => open ? '276px': '3000px'};
  height: 1px;
  margin-top: ${({open}) => open ? '30px': '15px'};
  background-color: ${({open}) => open ? '#2E2E2E': 'transparent'};
  transition: all 0.5s;
  opacity: 0.4;
`;

export default {
  ContainerMenu,
  ContainerGallery,
  ContentContainer,
  TextTitle,
  Text,
  ContainerTypow,
  Typ,
  MiddleLine,
  ContainerArrowDown,
  NextSectionDivider,
}