import styled from "styled-components";

const ContainerMenu = styled.div`
  padding-right: 60px;
  padding-top: 7px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  flex-shrink: 0;
  @media only screen and (max-width: 992px) {
    display: none;
    visibility: none;
  }
  @media only screen and (min-width: 992px) {
    width: 300px;
  }

  @media only screen and (min-width: 1200px) {
    width: 398px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1084px;
  }
`;


const ContainerGallery = styled.div`
  width: 200vw;
  @media only screen and (max-width: 992px) {
    display: none;
    visibility: none;
  }
`;

const TextTitle = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  width: 100%;
  border-bottom: 1px solid #11110E;
  text-align: left;
  line-height: 40px;
  @media only screen and (min-width: 992px) {
    width: 505px;
  }

  @media only screen and (min-width: 1200px) {
    width: 686px;
  }
`;

const Text = styled.div`
  color: #3F3F3F;
  font-size: 19px;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
  
  @media only screen and (min-width: 992px) {
    width: 505px;
  }

  @media only screen and (min-width: 1200px) {
    width: 686px;
  }
`;

export default {
  ContainerMenu,
  ContainerGallery,
  ContentContainer,
  TextTitle,
  Text,
}