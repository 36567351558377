import styled from "styled-components";


const Container = styled.div`
  width: 100%;
`;

const ContainerImages = styled.div`
  width: 100%;
  min-height: 580px;
  position: relative;
  overflow: hidden;
`;


const ContainerSequencer = styled.div`
  min-width: 300px;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: -6px;
  margin-bottom: 7px;
`;

const ContainerSequencerElement = styled.div`
width: 20px;
height: 20px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
  &: hover{
    & > svg > path{
      stroke: #707070;
      transition: all 0.2s;
    }
    & > div {
      background-color: #707070;
    }
  }
`;

const MiddleDot = styled.div`
  width:5px;
  height:5px;
  border-radius:5px;
  border: 1px solid #707070;
  ${({selected})=>selected && 'background-color: #707070;'}
  transition: all 0.7s;
`;

export default {
  Container,
  ContainerImages,
  ContainerSequencer,
  MiddleDot,
  ContainerSequencerElement,
}