import styled from "styled-components";

const PortfolioContainer = styled.div`
  width: 100%;
  min-height: 726px;
  box-sizing: border-box;
  margin:0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
`;

const ContainerGrid = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const ContainerList = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

const Title = styled.div`
  margin-top: 57px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 36px;
  line-height: 36px;
  color: #3E3E3E;
  letter-spacing: 0px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1080px;
  }
`;

const Subtitle = styled.div`
  margin-top: 25px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  color: #3E3E3E;
  max-width: 925px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 25px;
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1080px;
  }
`;

export default {
  PortfolioContainer,
  Title,
  Subtitle,
  ContainerGrid,
  ContainerList,
}