import React, { useEffect, useState } from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import imageLoader from 'helpers/imageLoader';
import { BarsSpinner   } from "react-spinners-kit";

const Image = ({ idx, element, selectedIndex}) => {
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        (async () => {
            setImageUrl('');
            const imageLoaded = await imageLoader(`${CMSAPI}${element.image.url}`);
            setImageUrl(imageLoaded);
        })();
    }, [element.image.url]);

    return (
        <styles.Image img={imageUrl} idx={idx} selectedIdx={selectedIndex}>
            {
                !imageUrl && <BarsSpinner  size={30} color="#686769"/>
            }
        </styles.Image>
    );
};

export default Image;
