import React, { useEffect, useState } from "react";
import styles from './styles.js';
import CMSAPI from "cmsapi";
import Sequencer from 'components/Sequencer';

const Gallery = ({ gallery, updateParentIndex, doorType }) => {

    const [selectedIndex, setSelectedIndex] = useState(0);

    const leftUnavailable = selectedIndex - 1 < 0;
    const rightUnavailable = selectedIndex + 1 >= gallery?.length;

    const goLeft = () => {
        const newIndex = leftUnavailable ? selectedIndex : selectedIndex - 1;
        updateIndex(newIndex);
    }
    const goRight = () => {
        const newIndex = rightUnavailable ? selectedIndex : selectedIndex + 1;
        updateIndex(newIndex);
    }

    const updateIndex = (idx) => {
        setSelectedIndex(idx);
        updateParentIndex && updateParentIndex(idx);
    }

    useEffect(() => {
        setSelectedIndex(0);
    }, [gallery])
    console.log(doorType);
    return (
        <styles.Container>
            <Sequencer selectedIndex={selectedIndex} setSelectedIndex={updateIndex} leftUnavailable={leftUnavailable} rightUnavailable={rightUnavailable} goLeft={goLeft} goRight={goRight} gallery={gallery} />
            <styles.ContainerImages>
                {gallery?.map((element, idx) => {
                    return <styles.ContainerDoor idx={idx} selectedIdx={selectedIndex}>
                        <styles.Image src={`${CMSAPI}${element.image.url}`} />
                        <styles.UnderDoorText>{element.name}</styles.UnderDoorText>
                    </styles.ContainerDoor>
                })}
            </styles.ContainerImages>
            <styles.ContainerText>
                {doorType?.description}
            </styles.ContainerText>
        </styles.Container>
    );
};

export default Gallery;
