import React from "react";
import styles from './styles.js';

const DotGrid = ({ gallery, selectedIndex, onSelectOption}) => {
    return (
        <styles.Container>
            {gallery?.map((_, idx) => <styles.Dot onClick={() => onSelectOption(idx)} idx={idx} selected={selectedIndex === idx} />)}
        </styles.Container>
    );
};

export default DotGrid;
