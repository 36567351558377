const imageLoader = async (url)=>{
    const myRequest = new Request(url);
    const response = await fetch(myRequest)
        .then(response => response.blob())
        .then(function (myBlob) {
            const objectURL = URL.createObjectURL(myBlob);
            return objectURL;
        });
    return response;
}

export default imageLoader;