import styled from "styled-components";

const PortfolioContainer = styled.div`
  width: 100%;
  min-height: 760px;
  box-sizing: border-box;
  margin:0px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  padding-bottom: 90px
`;

const Line = styled.div`
  height: 5px;
  background-color: #3F3F3F;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1080px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 805px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1080px;
  }
`;


const Title = styled.div`
  margin-top: 63px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 36px;
  line-height: 43.2px;
  color: #3E3E3E;
  letter-spacing: 0px
  text-align: center;
  @media only screen and (max-width: 600px) {
    width: 330px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 344.76px;
    margin-top: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;
  }
`;

const Subtitle = styled.div`
  margin-top: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  color: #3E3E3E;
  max-width: 925px;
  text-align: left;
  max-width: 95%;
  @media only screen and (max-width: 600px) {
    width: 405px;
  }

  @media only screen and (min-width: 600px) {
    width: 530px;
  }

  @media only screen and (min-width: 768px) {
    margin-top: 25px;
    width: 530px;
  }

  @media only screen and (min-width: 992px) {
    width: 100%;
    margin-top: 0px;
  }
`;

export default {
  PortfolioContainer,
  Title,
  Subtitle,
  Line,
  TextContainer,
}