import styled from "styled-components";

const Image = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  left: ${({idx, selectedIdx})=> ((idx - selectedIdx) * 100)}%;
  min-height: 580px;
  background-image: ${({img})=> `url(${img})`};
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Image,
}