import React from 'react';

const ListButton = ({ open, toggle }) => {
    return (
        <svg id="gal_Mozaika" data-name="gal Mozaika" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37">
            <g id="Rectangle_147156" data-name="Rectangle 147156" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-2" data-name="Rectangle 147156" transform="translate(13)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-3" data-name="Rectangle 147156" transform="translate(26)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-4" data-name="Rectangle 147156" transform="translate(0 13)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-5" data-name="Rectangle 147156" transform="translate(13 13)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-6" data-name="Rectangle 147156" transform="translate(26 13)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-7" data-name="Rectangle 147156" transform="translate(0 26)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-8" data-name="Rectangle 147156" transform="translate(13 26)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
            <g id="Rectangle_147156-9" data-name="Rectangle 147156" transform="translate(26 26)" fill="none" stroke="#c6c5c4" stroke-width="2">
                <rect width="11" height="11" stroke="none" />
                <rect x="1" y="1" width="9" height="9" fill="none" />
            </g>
        </svg>
    );
};

export default ListButton;