import styled from "styled-components";

export const SVG = styled.svg`
  &:hover{
    &>g{
      stroke: black;
      fill: none;
    }
    &>g>line{
      stroke: black;
    }
  }
`;