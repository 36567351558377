import styled from "styled-components";

const Square = styled.div`
  width: 255px;
  height: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 15px 10px;
  background-image: ${({image})=> `url(${image})`};
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 600px) {
    width: 189px;
    height: 189px;
    margin: 0px;
    border: 5px solid ${({backgroundColor})=> backgroundColor || "white"};
    overflow: hidden;
    box-sizing: border-box;
    max-width: 50%;
    ${({hiddenPhone})=> hiddenPhone && "display: none;"}
  }
`;

const SquareContent = styled.div`
  width: 100%;
  height: 100%;
  color: transparent;
  font-family: 'Roboto', sans-serif;
  font-stretch: condensed;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
    background-color: rgba(255,255,255,0.7);
    color: #434343;
  };
  transition: all 0.3s;
`; 

export default {
  Square,
  SquareContent,
}