import React from "react";
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import styles from './styles.js';

const Sequencer = ({ gallery, leftUnavailable, rightUnavailable, goLeft, goRight, selectedIndex, setSelectedIndex, onlyDots }) => {
    return (
        <styles.ContainerSequencer>
            {!onlyDots && <styles.ContainerSequencerElement onClick={goLeft} unavailable={leftUnavailable}><ArrowLeft /></styles.ContainerSequencerElement>}
            {gallery?.map((_, idx) => <styles.ContainerSequencerElement onClick={() => setSelectedIndex(idx)}><styles.MiddleDot selected={selectedIndex === idx} /></styles.ContainerSequencerElement>)}
            {!onlyDots && <styles.ContainerSequencerElement onClick={goRight} unavailable={rightUnavailable}><ArrowRight /></styles.ContainerSequencerElement>}
        </styles.ContainerSequencer>
    );
};

export default Sequencer;
